export const APIRequest = (url:string, method:string, headers:any|null, body:string|FormData|null, callback:(data:{result:{}|null, error:string|null}) => any) => {
    let result = null;
    let error = null;

    let fetchParam:any = {
        mode: 'cors',
        method: method
    };

    if (headers !== null) fetchParam['headers'] = headers;

    if (method === 'GET') {
        if (typeof body === 'string') {
            const params = new URLSearchParams(JSON.parse(body)).toString();
            url += '?' + params;
        }
    } else if (method === 'POST') {
        fetchParam['body'] = body;
    }
    
    fetch(url, fetchParam)
    .then(res => {
        if (!res.ok) { // error coming back from server
            if (window.location.href.indexOf("referralMobile") !== -1 && res.status === 401) {
                return res.json();
            } else {
                throw Error('could not fetch the data for that resource');
            }
        } 
        return res.json();
    })
    .then(data => {
        result = data;
        error = null;

        callback({result, error});
    })
    .catch(err => {
        // auto catches network / connection error
        result = null;
        error = err.message;
        callback({result, error});
    });
}