import AppContext from "./AppContext";
import * as React from 'react';
import PopupWrapper from "./PopupWrapper";
import IconError from '../images/Illust_Error.svg';

const MessagePopup = ( ) => {
    const appContext = React.useContext(AppContext);
    const messageClass = 'form-label text-[18px] font-bold text-center';

    return (
        <>
            <PopupWrapper isPopupOpen={appContext!.isPopupMessageOpen} setIsPopupOpen={appContext!.setIsPopupMessageOpen} isNested={false} withCloseButton={appContext!.popupContent.withCloseButton}>
                <>
                    <p className={(appContext!.popupContent.withCloseButton ? "text-left " : "text-center ") + "text-primary pt-[24px] px-[24px] mb-[24px] font-black text-[24px] leading-[27px] max-w-xs mx-auto"}>
                        { appContext!.popupContent.title} 
                    </p>
                    {
                        (appContext!.popupContent.isErrorPopup) ?
                        <div className="w-[67.03%] overflow-auto ml-[16.75%] mr-[16.75%] mb-[18px]">
                            <img className="popup-image" src={IconError} />
                        </div> : ""
                    }
                    <div className="mx-[24px]">
                        {appContext?.popupContent.isHTML && <p className={messageClass} dangerouslySetInnerHTML={{ __html: appContext.popupContent.message }}></p>}
                        {(typeof appContext?.popupContent.isHTML === 'undefined' || !appContext?.popupContent.isHTML) && <p className={messageClass}> { appContext!.popupContent.message } </p>}
                    </div>
                    <div className="mt-[24px] pb-[27px] rounded-b mr-[7.5%] ml-[8.67%] w-[83.2%]">
                        <div className="w-full overflow-hidden text-center transition cursor-pointer button button-orange button-shadow h-[45px]" onClick={() => {appContext!.setIsPopupMessageOpen(false)}}>
                            <div className="button_bg_hover"></div>
                            <div className="button_content text-[18px] text-campton font-bold relative leading-[45px] w-full" onClick={appContext!.popupContent.buttonCallback}>{(typeof appContext?.popupContent.buttonText === 'undefined' || appContext?.popupContent.buttonText === null) ? 'Ok' : appContext?.popupContent.buttonText}</div>
                        </div>
                    </div>
                </>
            </PopupWrapper>
        </>
    );
}
 
export default MessagePopup;