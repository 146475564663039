import PosterDataInterface from "../interfaces/PosterDataInterface";

export const JWT_TOKEN_VAR_NAME = 'token';
export const POSTER_DATA_VAR_NAME = 'poster';
export const USER_TYPE_PARENT = 3;
export const DEFAULT_SHARE_TEXT = 'Hi, I wanted to share an amazing educational product that my child and I have been using for a while now. It\'s called Geniebook, and it\'s been a fantastic resource for school students.\n' +
    'My child has always struggled with maths, but since we started using Geniebook, their grades have really improved! The platform provides personalised lessons and practice exercises that cater to their specific learning needs and pace. It\'s been a lifesaver for us!\n' +
    'If you\'d like to try it out for yourself, I have a referral poster with a QR code that you can scan to fill out a referral form. By using my referral, you\'ll get a 7-day free trial to see how it works and also an exclusive cashback if you end up buying a subscription. I\'m confident that you\'ll love it just as much as we do!';
export const ERROR_POPUP_TITLE = 'Error!';
export const ERROR_MESSAGE_DEFAULT = 'Something went wrong';
export const TOAST_TYPE_DEFAULT = 0;
export const TOAST_TYPE_SUCCESS = 1;
export const TOAST_TYPE_WARNING = 2;
export const TOAST_TYPE_ERROR = 3;
export const TOAST_DEFAULT_BUTTON_TEXT = 'Dismiss';
export const DEFAULT_LOADING_TITLE = 'Loading…';
export const DEFAULT_LOADING_MESSAGE = 'Please wait a while… We will be ready for you soon!';
export const DEFAULT_POSTER_INFO: PosterDataInterface = {
    child_name: "John Lee",
    subject_name: "Mathematics",
    num_bubbles: "1000",
    num_worksheets: "100",
    subject_pct: 80,
    expiry_date: 0
}
export const REFERRAL_MOBILE_STEP_MAIN_PAGE = 1;
export const REFERRAL_MOBILE_STEP_REWARD_LIST = 2;

// Prize (or Reward) type for Phase 1
export const PRIZE_TYPE = {
  INITIAL_BUBBLE: 1,
  UNDECIDED: -2,
} as const

// Reward Type for Phase 2 
export const REWARD_TYPE = {
  INITIAL_BUBBLE: 1,
  UNDECIDED: 2,
  CASHBACK: 3,
  BUBBLES: 4,
} as const;

export const REWARD_PHASE = {
  PHASE_ONE: 1,
  PHASE_TWO: 2,
} as const;

export const INIT_REWARD_ID = 1;

export const DEVICE_WEB = 1;
export const DEVICE_ANDROID = 2;
export const DEVICE_IOS = 3;