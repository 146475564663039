import {toPng, getFontEmbedCSS} from "html-to-image";
import React from "react";

export const getFontCSS = async (ref: React.RefObject<HTMLDivElement>) => {
    return await getFontEmbedCSS(ref.current!)
}

export const PosterImageHandler = (posterRef: React.RefObject<HTMLDivElement>, fontCSS:string, callback: (data : {poster_image: string}) => any) => {
    if (fontCSS !== ''){
        toPng(posterRef.current!, { cacheBust: true, quality: 0.90, width: 1200, height: 1600, canvasWidth:900, canvasHeight:1200, fontEmbedCSS: fontCSS})
            .then((poster_image) => {
                callback({poster_image})
            })
            .catch((err) => {
                // console.log(err)
            })
    }
}