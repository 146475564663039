import * as React from 'react';
import IconError from "../images/Illust_Error.svg";
import {
  Link
} from "react-router-dom";

export default function Error404() {
  return (
    <>
    <div className="page-not-found-container">
      <img className="popup-image mx-auto pt-[131.25px] h-[306px]" src={IconError} />
      <p className="text-primary pt-[24px] mb-[18px] text-[33px] leading-9 mx-auto font-extrabold"> 404. Page Not Found</p>
      <p className="font-campton font-thin text-[18px]">Oops! The page you are looking for does not exist.</p>
      <Link to="/">
        <div className="mt-[24px] rounded-b w-[166px] mx-auto">
            <div className="w-full overflow-hidden text-center transition cursor-pointer button button-orange button-shadow h-[45px]">
                <div className="button_bg_hover"></div>
                <div className="button_content text-[18px] text-campton font-bold relative leading-[45px]" >Back To Home</div>
            </div>
        </div>
      </Link>
    </div>
    </>
  );
}