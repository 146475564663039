const Footer = () => {
    return (
        <footer>
            <div className="footer-container">
                <p className="copyright">Copyright 2023 Geniebook Pte Ltd</p>
                <div className="tnc-pp-container">
                    <a href={process.env.REACT_APP_GENIEBOOK_HOMEPAGE_URL + 'terms'} target="_blank" className="tnc-pp mr-[12px]">Terms & Conditions</a>
                    &bull;
                    <a href={process.env.REACT_APP_GENIEBOOK_HOMEPAGE_URL + 'policies/privacy'} target="_blank" className="tnc-pp ml-[12px]">Privacy Policy</a>
                </div>
                <div className="clear-both"></div>
            </div>
        </footer>
    );
}
 
export default Footer;