import React from "react";
import { Accordion } from "@mantine/core";
import Caret from "../../images/icon-caret-up.svg";

const FaqSection = () => {
  const faqList = [
    {
      id: "item-1",
      control: "How do I refer someone to Geniebook?",
      panel: [
        "Share your referral link by clicking on the share button or copy and paste it on your preferred social media platform.",
        "If you wish to refer someone in person, have them scan your referral QR code (located in the top-right corner).",
        "If you wish to refer someone in person, have them scan your referral QR code (located in the top-right corner).",
      ],
    },
    {
      id: "item-2",
      control: "What happens after I refer someone to Geniebook?",
      panel: [
        "Once your referee submits the referral form, their contact details will be checked for authenticity.",
        'If your referee\'s details are authentic, they will appear on your "Invites and Rewards" page.',
        "Then, we will reach out to your referee to schedule a free analysis of their child's strengths and weaknesses.",
      ],
    },
    {
      id: "item-3",
      control: "Will the person I referred to Geniebook get a reward as well?",
      panel: [
        "Yes, your referee will also get an option to choose between SGD 80 in cash or 5,000 Bubbles.",
        "This reward will be credited to them after their 7-day refund period is over.",
      ],
    },
    {
      id: "item-4",
      control: "When will I get my reward?",
      panel: [
        "The referral initiation reward of 500 bubbles is a one-time reward, which you will receive in your Parent App on your first referral initiation.",
        "In the event of a successful referral, you will receive your selected reward only after the 7-day refund period of your referee is over.",
        "If you selected 5,000 Bubbles as your reward, it will be credited to your Parent App Bubble wallet.",
        "If you selected SGD 80 in cash as your reward, it will be credited to your bank account.",
      ],
    },
    {
      id: "item-5",
      control: "How will I know if my referral is successful?",
      panel: [
        'You can track the progress of each referral from the "Invites and Rewards" page.',
        "A referral is considered successful when your referee purchases a Geniebook subscription.",
        "Once your referee purchases a subscription, we will confirm your reward type and bank account details for crediting rewards, if needed.",
      ],
    },
    {
      id: "item-6",
      control: "How many parents can I refer? ",
      panel: [
        "You can refer as many parents as you wish. You and your referee will get rewards for each successful referral.",
      ],
    },
  ];

  return (
    <>
      <span className="text-primary font-bold mx-[15px] mb-2 mt-5 text-left font-dm-sans">
        How Referrals Work
      </span>
      <Accordion
        defaultValue="customization"
        chevron={
          <img
            src={Caret}
            alt="back"
            className="w-[10px] filter-orange"
            style={{ rotate: "180deg" }}
          />
        }
        className="text-left faq-accordion font-dm-sans"
      >
        {faqList.map((item, index) => (
          <Accordion.Item key={item.id} value={`item-${index}`}>
            <Accordion.Control>
              <div className="flex font-bold text-[#515151]">
                <div className="mr-2">&#8226;</div>
                <div className="font-dm-sans">{item.control}</div>
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              <ul className="ml-5 list-disc list-outside">
                {item.panel.map((text, subIndex) => (
                  <li key={`panel-${subIndex}`} className="ml-4 font-dm-sans">
                    {text}
                  </li>
                ))}
              </ul>
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
};

export default FaqSection;
