import React from "react";

export type BadgeProps = {
  variant: "green" | "yellow" | "red";
  className?: string;
};

const variantStyle = {
  green: "bg-[#48a074] text-white",
  yellow: "bg-[#fed00b] text-black",
  red: "bg-[#f77767] text-white",
};

const progressText = {
  green: "Credited",
  yellow: "In progress",
  red: "Not started",
};

const Badge = (props: BadgeProps) => {
  return (
    <span
      className={`flex items-center justify-center rounded-xl w-[77px] h-5 text-center text-[12px] leading-[18px] font-normal font-dm-sans ${
        variantStyle[props.variant]
      } ${props.className ?? ""}`}
    >
      {progressText[props.variant]}
    </span>
  );
};

export default Badge;
