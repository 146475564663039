import React, { useEffect, useState } from 'react';
import './Preloading.css';

const Preloading = ({ className }: { className?: string }) => {
  return (
    <div
      className={
        'preloading-container' +
        (className !== undefined ? ' ' + className : '')
      }
    >
      <div className="lds-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="preloading-text">
        <div className="preloading-header"></div>
        <span className="preloading-body">Loading</span>
      </div>
    </div>
  );
};

export default Preloading;
