import { useState, useEffect } from 'react';
import * as React from 'react';
import AppContext from "./AppContext";
import PrizesInterface from '../interfaces/PrizesInterface';
import PopupWrapper from './PopupWrapper';
import { APIRequest } from '../actions/APIRequest';
import { DEFAULT_LOADING_MESSAGE, DEFAULT_LOADING_TITLE, ERROR_MESSAGE_DEFAULT, ERROR_POPUP_TITLE, JWT_TOKEN_VAR_NAME } from '../actions/Constants';
import IconBubble from '../images/icon-bubble.svg';
import IconBubbleRedeemed from '../images/icon-bubble-redeemed.svg';
import IconDollar from '../images/icon-dollar.svg';
import IconRedeemed from '../images/icon-redeemed.svg';
import RedeemImage from '../images/Illust_Celebration.svg';
import TimeImage from '../images/time-image.png';
import PopupContentInterface from '../interfaces/PopupContentInterface';
import { useNavigate } from 'react-router';

const PrizeList = () => {
    const appContext = React.useContext(AppContext);
    const [prizes, setPrizes] = useState<Array<PrizesInterface>>([]);
    const [totalReferralCount, setTotalReferralCount] = useState<number>(0);
    const [referralCount, setReferralCount] = useState<number>(0);
    const [isRedeemPopupOpen, setIsRedeemPopupOpen] = useState<boolean>(false);
    const [lastTimeSeason, setLastTimeSeason] = useState<Date>(new Date());
    const [seasonDays, setSeasonDays] = useState<number>(0);
    const [seasonHour, setSeasonHour] = useState<number>(0);
    const [seasonMin, setSeasonMin] = useState<number>(0);
    const [seasonSec, setSeasonSec] = useState<number>(0);
    const [notificationMessage, setNotificationMessage] = useState<string>("");
    const [isFirstPrize, setIsFirstPrize] = useState<boolean>(true);
    const [isRedeemInProcess, setIsRedeemInProcess] = useState<boolean>(false);
    const seasonEndTime = new Date('2023-09-12');

    useEffect(() => {
        setLastTimeSeason(seasonEndTime);

        setInterval(() => {
            setSeasonTime(seasonEndTime);
        }, 1000);
    }, []);

    useEffect(() => {
        let token = (appContext?.userData !== null ? appContext?.userData[JWT_TOKEN_VAR_NAME] : null)

        if (token === null) {
            setReferralCount(0);
            getPrizeList(token, 0, 0);
        } else {
            let callback = (data:{result:any|null, error:string|null}) => {
                if (data.error !== null) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: data.error,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                } else {
                    const result = data.result;
                    if (result!.code !== 200) {
                        let messageProp:PopupContentInterface = {
                            title: ERROR_POPUP_TITLE,
                            message: ERROR_MESSAGE_DEFAULT,
                            image: null,
                            withCloseButton: true,
                            isErrorPopup: true,
                        }
                        appContext?.setPopupContent(messageProp);
                        appContext?.setIsPopupMessageOpen(true);
                    } else if (result!.message === 'error' && result.payload === null) {
                        let messageProp:PopupContentInterface = {
                            title: ERROR_POPUP_TITLE,
                            message: result.description,
                            image: null,
                            withCloseButton: true,
                            isErrorPopup: true,
                        }
                        appContext?.setPopupContent(messageProp);
                        appContext?.setIsPopupMessageOpen(true);
                        // show message box error from result.description
                    } else {
                        const payload = result.payload;
                        setTotalReferralCount(payload['totalReferral']);
                        setReferralCount(payload['totalSuccessReferral']);
                        getPrizeList(token, payload['totalReferral'], payload['totalSuccessReferral']);
                    }
                }
            }
            const headers = {
                'Authorization': token
            }
            APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'referral-progress/get-referral-count', 'GET', headers, null, callback);
        }
    }, [appContext?.userData]);

    function getPrizeList(token:string, totalReferral:number, totalSuccessReferral:number) {
        let prizeList:Array<PrizesInterface>;
        let callback = (data:{result:any|null, error:string|null}) => {
            if (data.error !== null) {
                let messageProp:PopupContentInterface = {
                    title: ERROR_POPUP_TITLE,
                    message: data.error,
                    image: null,
                    withCloseButton: true,
                    isErrorPopup: true,
                }
                appContext?.setPopupContent(messageProp);
                appContext?.setIsPopupMessageOpen(true);
            } else {
                const result = data.result;
                if (result!.code !== 200) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: ERROR_MESSAGE_DEFAULT,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                } else if (result!.message === 'error' && result.payload === null) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: result.description,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                    // show message box error from result.description
                } else {
                    prizeList = result.payload;
                    prizeList = setAvailablePrize(prizeList, totalReferral, totalSuccessReferral);
                    setPrizes(prizeList);
                }
            }
        }
        const headers = {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
        APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'referral-prize/list', 'GET', headers, null, callback);
    }

    function setAvailablePrize(prizeList:Array<PrizesInterface>, totalReferral:number, referralCount:number) {
        for (let i = 0; i < prizeList.length; i++) {
            if (prizeList[i].prize_id === 1) {
                if (totalReferral > 0) prizeList[i].available = 1;
            } else {
                if (prizeList[i].redeemed === 1 || referralCount >= prizeList[i].count_needed)
                    prizeList[i].available = 1;
            }
        }
  
        return prizeList;
    }

    function redeemPrize(prizeID:number) {
        if (isRedeemInProcess === false) {
            setIsRedeemInProcess(true);
            let token = (appContext?.userData !== null ? appContext?.userData[JWT_TOKEN_VAR_NAME] : null)
            let apiData = {
                prize_id: prizeID,
            };
            let callback = (data:{result:any|null, error:string|null}) => {
                if (data.error !== null) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: data.error,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                } else {
                    const result = data.result;
                    if (result!.code !== 200) {
                        let messageProp:PopupContentInterface = {
                            title: ERROR_POPUP_TITLE,
                            message: ERROR_MESSAGE_DEFAULT,
                            image: null,
                            withCloseButton: true,
                            isErrorPopup: true,
                        }
                        appContext?.setPopupContent(messageProp);
                        appContext?.setIsPopupMessageOpen(true);
                    } else if (result!.message === 'error' && result.payload === null) {
                        let messageProp:PopupContentInterface = {
                            title: ERROR_POPUP_TITLE,
                            message: result.description,
                            image: null,
                            withCloseButton: true,
                            isErrorPopup: true,
                        }
                        appContext?.setPopupContent(messageProp);
                        appContext?.setIsPopupMessageOpen(true);
                        // show message box error from result.description
                    } else {
                        let prizeList = result.payload;
                        let notificationMessage = "";
                        let prizeDescription = prizeList.find((x: { prize_id: number; }) => x.prize_id == prizeID).reward_description;

                        setIsRedeemPopupOpen(true);
                        if(prizeID ==  1) {
                            notificationMessage = "You have successfully redeemed {your reward}!"; 
                            setIsFirstPrize(true);
                        }
                        else {
                            notificationMessage = "You have successfully redeemed {your reward}! Our team will get in touch with you shortly.";
                            setIsFirstPrize(false);
                        }
                        notificationMessage = notificationMessage.replaceAll('{your reward}', prizeDescription);
                        setNotificationMessage(notificationMessage);

                        prizeList = setAvailablePrize(prizeList, totalReferralCount, referralCount);
                        setPrizes(prizeList);
                    }
                }
                appContext!.setIsPopupLoadingOpen(false);
                setIsRedeemInProcess(false);
            }
            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
            appContext!.setLoadingTitle(DEFAULT_LOADING_TITLE);
            appContext!.setLoadingMessage(DEFAULT_LOADING_MESSAGE);
            appContext!.setIsPopupLoadingOpen(true);
            APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'referral-prize/redeem-reward', 'POST', headers, JSON.stringify(apiData), callback);
        }
    }

    function setSeasonTime(endTime:Date) {
        let now = new Date();
        let diff = Math.abs(now.getTime() - endTime.getTime()) / 1000;

        setSeasonDays(Math.floor(diff / (3600*24)));
        setSeasonHour(Math.floor(diff % (3600*24) / 3600));
        setSeasonMin(Math.floor(diff % 3600 / 60));
        setSeasonSec(Math.floor(diff % 60));
    }

    return (
        <>
            <div className="season-countdown-container mb-[20.25px]">
                <p>Complete all the goals within this season period to claim a total of rewards <strong>worth $340</strong></p>
                {(new Date() <= seasonEndTime ? <div className="countdown-container">
                    <div className="time-image"><img src={TimeImage} /></div>
                    <div className="time-content">
                        <div className="season-countdown-label-container">
                            <p className="season-countdown-label">Season Countdown</p>
                        </div>
                        <div className="time-countdown">
                            <div className="time-container">
                                <div className="time-item">
                                    <p className="time-number">{ (seasonDays < 10 ? "0" : "") + seasonDays }</p>
                                    <p className="time-unit">Days</p>
                                </div>
                                <div className="time-item">
                                    <p className="time-number">{ (seasonHour < 10 ? "0" : "") + seasonHour }</p>
                                    <p className="time-unit">Hrs</p>
                                </div>
                                <div className="time-item time-separator">
                                    <p className="time-number">:</p>
                                    <p className="time-unit">:</p>
                                </div>
                                <div className="time-item">
                                    <p className="time-number">{ (seasonMin < 10 ? "0" : "") + seasonMin }</p>
                                    <p className="time-unit">Min</p>
                                </div>
                                <div className="time-item time-separator">
                                    <p className="time-number">:</p>
                                    <p className="time-unit">:</p>
                                </div>
                                <div className="time-item">
                                    <p className="time-number">{ (seasonSec < 10 ? "0" : "") + seasonSec }</p>
                                    <p className="time-unit">Sec</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : '')}
                
            </div>
            { 
                prizes?.map((prize) => (
                    <PrizeItem prize={prize} referralCount={referralCount} setIsRedeemPopupOpen={setIsRedeemPopupOpen} listPrize={prizes} key={prize.prize_id} redeemPrize={redeemPrize} />
                )) 
            }

            <PopupWrapper isPopupOpen={isRedeemPopupOpen} setIsPopupOpen={setIsRedeemPopupOpen} isNested={false} withCloseButton={false}>
                <>
                <p className="text-center text-primary pt-[24px] font-black text-[24px] leading-[24px] max-w-xs mx-auto">
                    Congratulations
                </p>
                <div className="mx-7">
                    <img className="h-[269.89px] mx-auto" src={RedeemImage} />
                    <p className="font-bold text-center text-primary text-[17px] leading-[20px] mb-[18px]">{notificationMessage}</p>
                    {
                        (isFirstPrize) ?
                        <p className="font-bold text-center text-primary text-[17px] leading-[20px]">Bubbles will be credited to your Geniebook parent account!</p>
                        : 
                        <p className="italic text-center text-primary text-[15px] leading-[17px]">Please note that the reward will be transferred after the 7-day refund period is over for the referee.</p>
                    }
                </div>
                <div className="pt-[24px] px-[24px] pb-[27px] rounded-b">
                    <div className="w-full overflow-hidden text-center transition cursor-pointer button button-orange button-shadow h-[45px]" onClick={() => {setIsRedeemPopupOpen(false)}}>
                        <div className="button_bg_hover"></div>
                        <div className="button_content text-[18px] text-campton font-bold relative leading-[45px]">Done</div>
                    </div>
                </div>
                </>
            </PopupWrapper>
        </>
    );
}

interface prizeItemInterface {
    prize: PrizesInterface;
    referralCount: number;
    listPrize: Array<PrizesInterface>;
    setIsRedeemPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
    redeemPrize: (prizeID:number) => void
}

const PrizeItem = (prop:prizeItemInterface) => {
    const appContext = React.useContext(AppContext);
    const navigate = useNavigate();
    const ordinals = ['st', 'nd', 'rd', 'th'];
    const taskName = prop.prize.prize_id === 1 ? '1st referral initiation' : prop.prize.count_needed+ (prop.prize.count_needed - 1 > ordinals.length ? ordinals[ordinals.length - 1] : ordinals[prop.prize.count_needed - 1]) +' successful referral';

    let circleClassName = 'circle-progress';
    if (prop.prize.number === 1) {
        if (prop.prize.redeemed === 1) circleClassName += ' redeemed';
        else circleClassName += ' available';
    } else {
        if (prop.prize.redeemed === 1) circleClassName += ' redeemed';
        else if (prop.listPrize[prop.prize.number - 2].available === 1) circleClassName += ' available';
    }

    let lineClassName = 'line-progress';
    if (prop.prize.number < prop.listPrize.length) {
        // redeemed means available == 1 also
        if (prop.listPrize[prop.prize.number - 1].available === 1) lineClassName += ' active';
    }

    const handleRedeem = () => {
        if (appContext!.userData !== null) {
            if (prop.prize.available === 1 && prop.prize.redeemed === 0) {
                prop.redeemPrize(prop.prize.prize_id);
            } else if (prop.prize.available === 0) {
                let messageProp:PopupContentInterface;
                if (prop.prize.prize_id === 1) {
                    messageProp = {
                        title: 'Unlock Goal To Redeem Bubbles',
                        message: '<p class="disabled-prize-popup-message">Share the referral link with your friends and receive this reward if they sign up!</p>' +
                                 '<p class="disabled-prize-popup-note">Note: We will automatically verify the contact details before crediting the reward.</p>',
                        image: null,
                        buttonText: 'Customise Poster',
                        isHTML: true,
                        withCloseButton: true,
                        isErrorPopup: false,
                        buttonCallback: () => {
                            navigate("/customise-poster");
                        }
                    }
                } else {
                    messageProp = {
                        title: 'Unlock Goal To Redeem Cashback',
                        message: 'Get ' + taskName + ' to receive this reward',
                        image: null,
                        buttonText: 'Okay',
                        withCloseButton: true,
                        isErrorPopup: false
                    }
                }
                appContext?.setPopupContent(messageProp);
                appContext?.setIsPopupMessageOpen(true);
            } else if (prop.prize.redeemed === 1) {
                let messageProp:PopupContentInterface = {
                    title: 'Redeemed',
                    message: 'You have successfully redeemed ' + prop.prize.reward_description + '!',
                    image: null,
                    buttonText: 'Okay',
                    withCloseButton: true,
                    isErrorPopup: false
                }
                appContext?.setPopupContent(messageProp);
                appContext?.setIsPopupMessageOpen(true);
            }
        } else {
            appContext!.setIsPopupLoginOpen(true);
        }
    }
    
    return (
        <>
            <div className="prize-item">
                <div className={ (prop.prize.redeemed === 1 ? "redeemed " : "") + "prize-progress"}>
                    { prop.prize.number === 1 ? <p className="start-label">START</p> : "" }
                    <div className={circleClassName}></div>
                    { prop.prize.number !== prop.listPrize.length ? <div className={lineClassName}></div> : "" }
                    { prop.prize.number === prop.listPrize.length ? <div className="goal-label-container"><p className="goal-label">GOAL</p></div> : "" }
                </div>
                <div className={(prop.prize.redeemed === 1 ? "redeemed ": "") + "prize-detail-container"}>
                    <div className="prize-image" onClick={handleRedeem}>
                        {prop.prize.prize_id === 1 && <img src={(prop.prize.redeemed === 1 ? IconBubbleRedeemed : IconBubble)} />}
                        {prop.prize.prize_id !== 1 && <img className={ (prop.prize.redeemed === 1 ? "redeemed " : "") + "dollar"} src={IconDollar} />}
                        <label>{prop.prize.reward}</label>
                    </div>
                    <div className="prize-details">
                        <div className="prize-detail">
                            <p className="title" onClick={handleRedeem}>{prop.prize.reward_description}</p>
                            <p className="requirement" onClick={handleRedeem}>{taskName}</p>
                        </div>
                        {
                            prop.prize.redeemed === 0 ?
                                <div className={((prop.prize.available !== 1 ? " disabled " : "")) + "button-container"}>
                                    <div className="overflow-hidden text-center transition cursor-pointer button button-orange button-shadow w-[90.795px] h-[33.75px] max-lg:w-[121.06px] max-lg:h-[45px] max-md:w-[92.76px] max-md:h-[31.6px] max-2xs:w-[82px] max-2xs:h-[30px]" onClick={handleRedeem}>
                                        <div className="button_bg_hover"></div>
                                        <div className="button_content text-[11.25px] text-campton font-bold relative leading-[33.75px] max-lg:text-[15px] max-lg:leading-[45px] max-md:text-[15px] max-md:leading-[31.6px] max-2xs:text-[12px] max-2xs:leading-[30px]">Redeem<span className="max-md:hidden"> Now</span></div>
                                    </div>
                                    <div className="button_bg_disabled"></div>
                                </div> :
                                <img className="icon-redeemed" src={IconRedeemed} />
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
 
export default PrizeList;