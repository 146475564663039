import {JWT_TOKEN_VAR_NAME, POSTER_DATA_VAR_NAME} from "./Constants";
import PosterDataInterface from "../interfaces/PosterDataInterface";
import {APIRequest} from "./APIRequest";


export const PosterInfoHandler = (token: string, callback: (data: { poster_info: PosterDataInterface }) => any) => {
    const poster_data = localStorage.getItem(POSTER_DATA_VAR_NAME);
    let poster_info: PosterDataInterface
    if (token !== null) {
        if (poster_data !== null) {
            let poster_data_object: PosterDataInterface = JSON.parse(poster_data)
            //Check Expiry Date
            let currentDate = Date.now()
            if (poster_data_object.expiry_date < currentDate) {
                RequestPosterData((data: { posterData: PosterDataInterface })=>{
                    poster_info = data.posterData
                    callback({poster_info})
                })
            } else {
                poster_info = poster_data_object
                callback({poster_info})
            }
        } else {
            RequestPosterData((data: { posterData: PosterDataInterface })=>{
                poster_info = data.posterData
                callback({poster_info})
            })
        }
    }
}

const RequestPosterData = (callback: (data: { posterData: PosterDataInterface }) => any) => {
    let token = localStorage.getItem(JWT_TOKEN_VAR_NAME);
    let headers = {
        'Authorization': token,
        'Content-Type' : 'application/json'
    };
    APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'referral-poster/poster-info', 'POST', headers, null, (data:{result:any|null, error:string|null}) => {
        if (data.error !== null) {
            // show message box error
        } else {
            const result = data.result;
            if (result!.code !== 200) {
                // show message box error from result!.error_msg
            } else {
                const payload = result.payload
                let date: Date = new Date();
                let expiry_date = date.setDate(date.getDate() + 1);

                let posterData: PosterDataInterface = {
                    child_name: payload.student_name,
                    subject_name: payload.improvement_percentage.subject_name,
                    num_bubbles: payload.bubbles,
                    num_worksheets: payload.worksheet,
                    subject_pct: payload.improvement_percentage.percentage,
                    expiry_date: expiry_date
                }

                localStorage.setItem(POSTER_DATA_VAR_NAME, JSON.stringify(posterData))
                callback({posterData})
            }
        }
    });
}

