import AppContext from "./AppContext";
import * as React from 'react';
import {APIRequest} from "../actions/APIRequest";
import jwt_decode from "jwt-decode";
import { DEFAULT_LOADING_MESSAGE, DEFAULT_LOADING_TITLE, ERROR_MESSAGE_DEFAULT, JWT_TOKEN_VAR_NAME, USER_TYPE_PARENT } from "../actions/Constants";
import { ERROR_POPUP_TITLE } from "../actions/Constants";
import PopupWrapper from "./PopupWrapper";
import PopupContentInterface from "../interfaces/PopupContentInterface";

const LoginPopup = () => {
    const appContext = React.useContext(AppContext);
    const [email, setEmail] = React.useState<string>('');
    const [emailErrorMsg, setEmailErrorMsg] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [passwordErrorMsg, setPasswordErrorMsg] = React.useState<string>('');

    const handleEmailOnBlur = () => {
        if (email === '') setEmailErrorMsg('Email is required');
        else setEmailErrorMsg('');
        if (ValidateEmail(email) === false) setEmailErrorMsg('Invalid email address');
        else setEmailErrorMsg('');
    }

    const handlePasswordOnBlur = () => {
        if (password === '') setPasswordErrorMsg('Password is required');
        else setPasswordErrorMsg('');
    }

    const handleSubmit = (e:React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        submitForm();
    }

    function submitForm() {
        if (emailErrorMsg === '' && passwordErrorMsg === '') {
            // contact BackEnd to login, return data
            let apiData = {
                username: email,
                password: password,
                secret_key: process.env.REACT_APP_GENIEBOOK_PARENT_API_KEY
            };

            let callback = (data:{result:any|null, error:string|null}) => {
                if (data.error !== null) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: data.error,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                } else {
                    const result = data.result.payload;
                    if (result!.error === true) {
                        let messageProp:PopupContentInterface = {
                            title: ERROR_POPUP_TITLE,
                            message: result.error_msg,
                            image: null,
                            withCloseButton: true,
                            isErrorPopup: true,
                        }
                        appContext?.setPopupContent(messageProp);
                        appContext?.setIsPopupMessageOpen(true);
                    } else {
                        let sessionData:any = jwt_decode(result.data);
                        sessionData[JWT_TOKEN_VAR_NAME] = result.data;
                        localStorage.setItem(JWT_TOKEN_VAR_NAME, result.data);
                        appContext!.setUserData(sessionData);
                        appContext!.setIsPopupLoginOpen(false);
                    }
                }
                appContext!.setIsPopupLoadingOpen(false);
            }
            appContext!.setLoadingTitle(DEFAULT_LOADING_TITLE);
            appContext!.setLoadingMessage(DEFAULT_LOADING_MESSAGE);
            appContext!.setIsPopupLoadingOpen(true);
            APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'auth', 'POST', null, JSON.stringify(apiData), callback);
        }
    }

    function ValidateEmail(email:string):boolean {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
        if (email.match(validRegex)) return true;
        return false;
    }

    return (
        <>
            <PopupWrapper isPopupOpen={appContext!.isPopupLoginOpen} setIsPopupOpen={appContext!.setIsPopupLoginOpen} isNested={true} withCloseButton={true}>
                <>
                    <p className="text-left text-primary py-[24px] font-black text-[24px] leading-[27px] max-w-xs mx-7 w-[calc(100%-70px)]">
                        Login to your Geniebook parent account
                    </p>
                    <form onSubmit={handleSubmit}>
                        <div className="mx-7">
                            <label className="form-label">Email</label>
                            <input type="email" className={(emailErrorMsg !== '' ? 'error ' : 'mb-4 ') + "form-control"} id="emailLogin" placeholder="johndoe@gmail.com" required onInput={(e:React.ChangeEvent<HTMLInputElement>) => {setEmail(e.target.value)}} value={email} onBlur={handleEmailOnBlur} />
                            { emailErrorMsg !== '' ? <p className="mb-4 error-message">{emailErrorMsg}</p> : '' }
                            <label className="form-label">Password</label>
                            <input type="password" className={(passwordErrorMsg !== '' ? 'error ' : 'mb-3 ') + "form-control"} id="passwordLogin" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;" required onInput={(e:React.ChangeEvent<HTMLInputElement>) => {setPassword(e.target.value)}} value={password} onBlur={handlePasswordOnBlur} />
                            { passwordErrorMsg !== '' ? <p className="mb-3 error-message">{passwordErrorMsg}</p> : '' }
                            <a href={ process.env.REACT_APP_GENIEBOOK_APP_URL + "login/index_forgot_pw" } target="_blank" className="float-right font-medium text-primary text-size-primary">Forget password?</a>
                        </div>
                        <div className="py-6 mt-[18px] rounded-b px-[21px]">
                            <div id="tracker-login" className="w-full overflow-hidden text-center transition cursor-pointer button button-orange button-shadow h-[45px]" onClick={() => {submitForm()}}>
								<div className="button_bg_hover"></div>
								<div className="button_content text-[18px] text-campton font-bold relative leading-[45px]">Login</div>
							</div>
                            <button type="submit" className="hidden"></button>
                            </div>
                    </form>
                </>
            </PopupWrapper>
        </>
    );
}
 
export default LoginPopup;