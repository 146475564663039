import React, { useEffect, useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { detectDeviceParentDownload } from '../actions/detectdevice';
import './ClosedPage.css';

const ClosedPage = () => {
    const [deviceLink, setDeviceLink] = useState<string>(
        'https://play.google.com/store/apps/details?id=com.geniebook.parent',
      );
    const googleLink: string = 'https://play.google.com/store/apps/details?id=com.geniebook.parent';
    const iosLink: string = 'https://apps.apple.com/sg/app/geniebook-parent/id1301096464';

    useEffect(() => {
        const device = detectDeviceParentDownload();
        if (device === 'ios') {
          setDeviceLink(iosLink);
        } else {
          setDeviceLink(googleLink);
        }
    }, []);

  return (
    <div className='flex h-[400px] bg-white rounded-[20px] items-center px-[98px] gap-20 shadow-[0px_-5px_1px_0px_#D0D7E4] max-lg:p-[64px] max-lg:block max-lg:h-auto max-md:bg-transparent max-md:shadow-none max-md:p-0'>
      <div>
        <p className='text-left text-[44px] text-primary font-campton leading-[53px] max-lg:text-[44px] max-lg:text-center max-md:text-[27px] max-md:leading-8'>This page has moved</p>
        <div className='hidden my-[32px] max-lg:flex max-lg:justify-center max-lg:items-center'>
            <div className='w-[208px]'>
                <QRCodeElement deviceLink={deviceLink} />
            </div>
        </div>
        <p className='text-left my-8 text-primary text-[21px] font-campton leading-[25px] max-lg:mt-0 max-lg:text-center max-md:text-[18px] max-md:leading-[22px]'>The referral program is now located in the Geniebook Parent app for our Singapore users, ensuring a much smoother experience.</p>
        <p className='text-left text-primary text-[18px] font-campton font-bold leading-[22px] max-lg:text-center max-md:text-[17px]'>Don't worry, your previous and ongoing referral records remain untouched!</p>
      </div>
      <div className='min-w-[208px] max-lg:hidden'>
        <QRCodeElement deviceLink={deviceLink} />
      </div>
    </div>
  );
};

const QRCodeElement = ({deviceLink} : {deviceLink: string;}) => {
    return <>
        <div className='-mt-[10px]'><QRCode ecLevel={"L"} value={deviceLink} size={188} logoPadding={0} bgColor='transparent'/></div>
        <p className='text-secondary font-campton font-bold text-[18px] mt-[10px] leading-[22px] max-md:text-[17px]'>Scan this QR code to launch the Parent App!</p>
    </>
}

export default ClosedPage;
