import * as React from 'react';
import Popup from "reactjs-popup";
import RocketImage from '../images/rocket-image.gif';
import AppContext from './AppContext';

const Loading = () => {
    const appContext = React.useContext(AppContext);

    return (
        <Popup open={appContext!.isPopupLoadingOpen} className="popup-overlay" closeOnDocumentClick={false} modal lockScroll>
            <div className="relative w-full h-full max-w-[90vw] mx-auto md:h-auto">
                <div className="relative bg-white rounded-lg shadow min-h-[228.75px] w-[318px] p-[24px] max-w-[100%]">
                    <p className="text-center font-extrabold text-[24px] text-primary mb-[7.5px]">{ appContext!.loadingTitle }</p>
                    <img className="w-[206.25px] h-[93.75px] mx-auto" src={RocketImage} />
                    <p className="font-bold text-center text-primary text-[18px] mt-[7.5px]">{ appContext!.loadingMessage }</p>
                </div>
            </div>
        </Popup>
    );
}
 
export default Loading;