import { useState, useEffect } from 'react';
import * as React from 'react';
import AppContext from "./AppContext";
import ReferralInterface from '../interfaces/ReferralInterface';
import PopupWrapper from './PopupWrapper';
import ProgressDetailInterface from '../interfaces/ProgressDetailInterface';
import { ERROR_MESSAGE_DEFAULT, ERROR_POPUP_TITLE, JWT_TOKEN_VAR_NAME } from '../actions/Constants';
import { APIRequest } from '../actions/APIRequest';
import IconReferralSuccess from '../images/icon-referral-success.svg';
import IconCheck from '../images/icon-check.svg';
import IconReminder from '../images/Illust_Reminder.svg';
import IconNoReferrals from '../images/Illust_Active_Referrals.svg';
import PopupContentInterface from '../interfaces/PopupContentInterface';

const ActiveReferrals = () => {
    const appContext = React.useContext(AppContext);
    const [referrals, setReferrals] = useState<Array<ReferralInterface>>([]);
    const [nextPrize, setNextPrize] = useState<string>('');
    const [isRefDetailPopupOpen, setIsRefDetailPopupOpen] = useState<boolean>(false);
    const [referralDetail, setReferralDetail] = useState<ReferralInterface|null>(null);
    const [noReferralMessage, setNoReferralMessage] = useState<string>('');
    const progress_detail:Array<ProgressDetailInterface> = [
        {
            progress_id: 1,
            short_desc: 'In contact with Educational Consultant',
            desc: 'Referee has initiated chat with Educational Consultant'
        },
        {
            progress_id: 2,
            short_desc: 'Diagnostic Assessment appointment scheduled',
            desc: 'Diagnostic Assessment appointment scheduled'
        },
        {
            progress_id: 3,
            short_desc: 'Diagnostic Assessment completed',
            desc: 'Diagnostic Assessment completed'
        },
        {
            progress_id: 4,
            short_desc: 'Subscription requested',
            desc: 'Subscription requested'
        },
        {
            progress_id: 5,
            short_desc: 'Subscription bought',
            desc: 'Subscription bought'
        },
    ]

    const handleOpenDetail = (parent_id:number) => {
        if (appContext!.userData !== null) {
            let referral = referrals.filter(function (el) {
                return el.parent_id === parent_id;
            });
    
            setReferralDetail(referral[0]);
            setIsRefDetailPopupOpen(true);
        } else appContext!.setIsPopupLoginOpen(true);
    }

    useEffect(() => {
        let referralList:Array<ReferralInterface>;
        let nextPrizeName = '';
        let token = localStorage.getItem(JWT_TOKEN_VAR_NAME);

        if (token !== null) setNoReferralMessage('You don\'t have any active referrals yet. You can begin by sharing your link or poster!')
        else setNoReferralMessage('Login using your Geniebook Parent credentials to start tracking your referrals!');

        let callback = (data:{result:any|null, error:string|null}) => {
            if (data.error !== null) {
                let messageProp:PopupContentInterface = {
                    title: ERROR_POPUP_TITLE,
                    message: data.error,
                    image: null,
                    withCloseButton: true,
                    isErrorPopup: true,
                }
                appContext?.setPopupContent(messageProp);
                appContext?.setIsPopupMessageOpen(true);
            } else {
                const result = data.result;
                if (result!.code !== 200) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: ERROR_MESSAGE_DEFAULT,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                } else if (result!.message === 'error' && result.payload === null) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: result.description,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                    // show message box error from result.description
                } else {
                    referralList = result.payload.data;
                    referralList = setProgressReferrals(referralList);
                    nextPrizeName = result.payload.nextReward;
                    setReferrals(referralList);
                    setNextPrize(nextPrizeName);
                }
            }
        }
        const headers = {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
        APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'referral-progress/list', 'GET', headers, null, callback);
    }, [appContext?.userData]);

    function setProgressReferrals(referralList:any) {
        for (let i = 0; i < referralList.length; i++) {
            referralList[i]['progress_value'] = Math.round(referralList[i]['progress_id'] / progress_detail.length * 100)
            if (referralList[i]['progress_value'] > 100) referralList[i]['progress_value'] = 100;
        }
  
        return referralList;
    }

    return (
        <>
            { 
                (referrals.length > 0) ?
                <>
                    <div className="referral-next-reward">
                        <img className="icon-next-referral" src={IconReminder} />
                        <div className="next-reward-label">
                            <div className="vertical-center">
                                {
                                    (nextPrize !== '') ?
                                    <>
                                        <span>One more successful referral to claim </span>
                                        <span className="font-extrabold">{nextPrize}</span>
                                        <span> reward!</span>
                                    </>
                                    : 
                                    <span>You have successfully claimed all referral rewards!</span>
                                }
                            </div>
                        </div>
                    </div> 
                    <div className='max-h-[436.5px] overflow-y-auto'>
                        {
                            referrals?.map((referral) => (
                                <ReferralItem referral={referral} progress_detail={progress_detail} handleOpenDetail={handleOpenDetail}  key={referral.parent_id} />
                            ))
                        }
                    </div>
                </> :
                <>
                    <img className="icon-next-referral w-[48.48%] mx-auto mt-[18.6%] mb-[5.79%]" src={IconNoReferrals} />
                    <h2 className="text-primary w-[54.78%] mx-auto mb-[25.28%]"> {noReferralMessage}</h2>
                </>
            }

            <PopupWrapper isPopupOpen={isRefDetailPopupOpen} setIsPopupOpen={setIsRefDetailPopupOpen} isNested={false} withCloseButton={true}>
                <>
                    <div className="active-referral-title-container">
                        <p id="referralDetailTitle">
                            <div className="referral-detail-student-name" title={referralDetail !== null ? referralDetail.name : "[name]"}>{referralDetail !== null ? referralDetail.name : "[name]"}</div> is {referralDetail !== null ? referralDetail.progress_value : "0"}% completed with their journey
                        </p>
                    </div>
                    <div className="mx-5 progress-detail-container">
                        {
                            progress_detail?.map((detail) => {
                                return (<ProgressDetailItem detail={detail} referralDetail={referralDetail} progress_detail={progress_detail} key={detail.progress_id} />);
                            })
                        }
                    </div>
                    <div className="w-full rounded-b referral-popup-button">
                        <div className="overflow-hidden text-center transition cursor-pointer button button-orange button-shadow h-[33.75px]" onClick={() => {setIsRefDetailPopupOpen(false)}}>
                            <div className="button_bg_hover"></div>
                            <div className="button_content text-[18px] text-campton font-bold relative leading-[33.75px]">
                                <div className="button-label-pc">
                                    <p>Close</p>
                                </div>
                                <div className="button-label-mobilep">
                                    <p>Done</p>
                                </div>
                            </div>
                        </div>
                        <div className="button_bg_disabled"></div>
                    </div>
                </>
            </PopupWrapper>
        </>
    );
}

interface referralItemInterface {
    referral: ReferralInterface;
    progress_detail: Array<ProgressDetailInterface>;
    handleOpenDetail: (parent_id: number) => void;
}

interface progressDetailItem {
    detail: ProgressDetailInterface;
    referralDetail: ReferralInterface|null;
    progress_detail:Array<ProgressDetailInterface>;
}

const ProgressDetailItem = (prop:progressDetailItem) => {
    const appContext = React.useContext(AppContext);
    
    return (
        <div className={(prop.referralDetail !== null && prop.detail.progress_id <= prop.referralDetail.progress_id + 1 ? (prop.referralDetail !== null && prop.detail.progress_id <= prop.referralDetail.progress_id ? "active " : "next-goal ") : "") + "progress-detail-item"} id={"progressDetailItem" + prop.detail.progress_id}>
            <div className="detail-number">
                {
                    prop.referralDetail !== null && prop.detail.progress_id <= prop.referralDetail.progress_id ?
                    <div className="check-circle">
                        <img className="icon-referral-check" src={IconCheck} />
                    </div>
                    :
                    <div className="number-circle">{prop.detail.progress_id}</div>
                }
                {prop.detail.progress_id !== prop.progress_detail.length && <div className={(prop.referralDetail !== null && prop.detail.progress_id < prop.referralDetail.progress_id + 1 ? "active " : "") + "next-line"}></div>}
            </div>
            <div className="detail-desc-container">
            <label className="detail-desc">{prop.detail.desc}</label>
            </div>
            <div className="clear-both"></div>
        </div>
    )
};

const ReferralItem = (prop:referralItemInterface) => {
    const appContext = React.useContext(AppContext);
    
    return (
        <>
            <div className={(prop.referral.progress_value === 100 ? "success " : "") + "referral-item"}>
                <div className="referral-short-detail">
                    <p className={(prop.referral.progress_value === 100 ? "success " : "") + "referral-name"}>{prop.referral.name}</p>
                    <div className="progress-bar-container">
                        <div className="progress-bar progress-bar-body">
                            <div className={(prop.referral.progress_value === 100 ? "success " : "") + "progress-bar progress-bar-value"} style={{width: prop.referral.progress_value+"%"}}></div>
                        </div>
                        <label className={(prop.referral.progress_value === 100 ? "success " : "") + "progress-detail"}>{prop.referral.progress_value + "%"}</label>
                    </div>
                    <div className={(prop.referral.progress_value === 100 ? "success " : "") + "referral-short-desc"}>
                        <p>{prop.progress_detail[prop.referral.progress_id - 1].short_desc}</p>
                    </div>
                </div>
                {
                    prop.referral.progress_value !== 100 ?
                    <div className="float-right button button-view-detail" onClick={() => {prop.handleOpenDetail(prop.referral.parent_id)}}>
                        <div className="button-label-pc">
                            <p>View details</p>
                        </div>
                        <div className="button-label-mobile">
                            <p>Details</p>
                        </div>
                    </div>
                    :
                    <img className="icon-referral-success" src={IconReferralSuccess} />
                }
                <div className="clear-both"></div>
            </div>
        </>
    );
};
 
export default ActiveReferrals;