import React, { useEffect } from 'react';
import RewardItem from '../../components/referral-mobile/RewardItem';
import { ParentReferralReward, getRefereeName } from '../ReferralMobile';

const YourInvitesAndRewards = ({
  rewardList,
}: {
  rewardList: ParentReferralReward[];
}) => {
  return (
    <>
      <div className='justify-center min-h-screen pt-[7px] pb-[10px] bg-white'>
        {rewardList.map((item) => (
          <RewardItem
            key={item.parent_referral_reward_id}
            prizeId={item.prize_id}
            rewardDescription={item.reward_description}
            version={item.version_reward}
            status={{
              delivered: item.status_delivered,
              redeemed: item.status_redeemed,
              progress: item.referral_progress_id,
            }}
            referee={getRefereeName(item, rewardList)}
            parentReferralRewardID={item.parent_referral_reward_id}
          />
        ))}
      </div>
    </>
  );
};

export default YourInvitesAndRewards;
