import React, {forwardRef, Ref, useEffect} from 'react';
import { QRCode } from 'react-qrcode-logo';
import logo from "../images/poster/Logo_other_variation.svg";
import PosterDataInterface from "../interfaces/PosterDataInterface";
import photo from "../images/default-child-photo/default-1.jpg";
import posterIcon1 from "../images/poster/Poster_Icon_1.svg";
import posterIcon3 from "../images/poster/Poster_Icon_3.svg";
import posterIcon2 from "../images/poster/Poster_Icon_2.svg";

export interface PropsType {
    getPhotoSrc: string;
    qrShareLink: string;
    posterData : PosterDataInterface;
}

const Poster2 = forwardRef((props: PropsType, ref: Ref<HTMLDivElement>) => {

    useEffect(()=>{}, [props.getPhotoSrc, props.qrShareLink])

    return (
        <>
            <div ref={ref}>
                {/* DOM nodes you want to convert to PNG */}
                <div className="static h-[1600px] w-[1200px] bg-[#27AAE1]">
                    <div className="absolute">
                        <img className="pt-[62px] pl-[70px]" src={logo} style={{height: "auto", width: "374px"}} alt=""/>
                        <div className="pt-[48px] pl-[70px]">
                            <h1 className="h-[103px] w-[1042px] font-black text-[86px] text-[#262262] leading-[103px]">Personalise your</h1>
                            <h1 className="h-[103px] w-[1042px] font-black text-[86px] text-[#262262] leading-[103px]">child’s learning journey</h1>
                        </div>


                        <div className="pt-[22px] pl-[70px]">
                            <p className="h-[72px] w-[1061px] font-black text-[60px] text-[#FFFFFF] leading-[72px]">Since joining Geniebook, my child</p>
                            <p className="h-[72px] w-[1061px] font-black text-[60px] text-[#FFFFFF] leading-[72px]">{props.posterData.child_name.length > 20 ? (props.posterData.child_name.match(/[\u3400-\u9FBF]/) ? props.posterData.child_name.substring(0,10) : props.posterData.child_name.substring(0,20))+ "..." : props.posterData.child_name} has...</p>
                        </div>
                    </div>

                    <div className="absolute top-[528px] left-[665px] w-[535px] h-[707px] overflow-hidden">
                        <div className="w-[707px] h-[707px] rounded-[50%] overflow-hidden">
                            <img className="inline w-auto h-[707px] mx-auto ml-[0px]" src={props.getPhotoSrc} />
                        </div>
                    </div>

                    <div>
                        <div className="absolute top-[579px] left-[135px] bg-[#F7941E] rounded-bl-[90px] rounded-tr-[90px] rounded-br-[90px]" style={{height: "141px", width: "515px"}}></div>
                        <div className="absolute w-[135px] h-[135px] top-[579px] left-[70px] bg-[#262262] rounded-[50%]"></div>
                        <img className="absolute top-[604px] left-[88px] w-[93px] h-[89px]" src={posterIcon1} />
                        <p className="absolute top-[609px] left-[220px] text-left h-[82px] w-[364px] font-black text-[34px] text-[#FFFFFF] leading-[41px]">Completed {props.posterData.num_worksheets} Worksheets</p>
                    </div>

                    <div>
                        <div className="absolute top-[760px] left-[135px] bg-[#F7941E] rounded-bl-[90px] rounded-tr-[90px] rounded-br-[90px]" style={{height: "141px", width: "515px"}}></div>
                        <div className="absolute w-[135px] h-[135px] top-[760px] left-[70px] bg-[#262262] rounded-[50%]"></div>
                        <img className="absolute top-[779px] left-[100px] w-[71px] h-[100px]" src={posterIcon3} />
                        <p className="absolute top-[790px] left-[220px] text-left h-[82px] w-[364px] font-black text-[34px] text-[#FFFFFF] leading-[41px]">Improved {props.posterData.subject_pct}% for {props.posterData.subject_name.length > 18 ? props.posterData.subject_name.substring(0,18) + "..." : props.posterData.subject_name }</p>
                    </div>

                    <div>
                        <div className="absolute top-[941px] left-[135px] bg-[#F7941E] rounded-bl-[90px] rounded-tr-[90px] rounded-br-[90px]" style={{height: "182px", width: "515px"}}></div>
                        <div className="absolute w-[135px] h-[135px] top-[941px] left-[70px] bg-[#262262] rounded-[50%]"></div>
                        <img className="absolute top-[966px] left-[104px] w-[74px] h-[89px]" src={posterIcon2} />
                        <p className="absolute top-[971px] left-[220px] text-left h-[123px] w-[376px] font-black text-[34px] text-[#FFFFFF] leading-[41px]">Earned {(+props.posterData.num_bubbles).toLocaleString()} Bubbles to redeem for free items</p>
                    </div>

                    <div className="absolute pt-[1168px] pl-[70px]">
                        <p className="h-[104px] w-[670px] text-left font-black text-[44px] text-[#262262] leading-[52px]">Join Geniebook to accelerate your child’s learning today!</p>
                    </div>

                    <div className="absolute pt-[1303px] pl-[70px]">
                        <div className="absolute bg-[#FFFFFF] rounded-[34px]" style={{height: "233px", width: "236px"}}></div>
                        <div className="absolute top-[1336px] left-[306px] bg-[#262262] rounded-tr-[100px] rounded-br-[100px]" style={{height: "168px", width: "825px"}}></div>
                        <p className="absolute top-[1365px] left-[366px] h-[108px] w-[688px] text-left font-black text-[45px] text-[#FFFFFF] leading-[54px]">Scan this QR code and receive $70 cash back upon signing up!</p>
                        <div className="absolute top-[1320px] left-[90px]">
                            <QRCode ecLevel={"L"} value={props.qrShareLink} size={180}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default Poster2;