import * as React from 'react';
import { useNavigate } from "react-router-dom";
import AppContext from "../components/AppContext";
import IconChevronLeft from '../images/icon-chevron-left.svg';
import IconChevronRight from '../images/icon-chevron-right.svg';
import IconPlus from '../images/icon-plus.svg';
import IconReload from '../images/icon-reload.svg';
import IconLink from '../images/icon-link.svg';
import IconDelete from '../images/xmark-solid.svg';
import DefaultChildPhoto1 from '../images/default-child-photo/default-1.jpg';
import DefaultChildPhoto2 from '../images/default-child-photo/default-2.jpg';
import {
    WhatsappShareButton,
    WhatsappIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon
  } from 'react-share'
import {
    ERROR_MESSAGE_DEFAULT,
    ERROR_POPUP_TITLE,
    JWT_TOKEN_VAR_NAME,
    TOAST_DEFAULT_BUTTON_TEXT,
    TOAST_TYPE_DEFAULT,
    TOAST_TYPE_SUCCESS
} from '../actions/Constants';
import { APIRequest } from '../actions/APIRequest';
import Poster1 from '../components/Poster1';
import Poster2 from '../components/Poster2';
import {useStateCallback} from '../actions/UseStateCallback';
import DefaultPoster1 from "../images/poster/default-poster1.png";
import DefaultPoster2 from "../images/poster/default-poster2.png";

import PopupContentInterface from '../interfaces/PopupContentInterface';
import ShareMorePopup from '../components/ShareMorePopup';
import PosterDataInterface from "../interfaces/PosterDataInterface";
import {useState} from "react";
import {PosterInfoHandler} from "../actions/PosterInfoHandler";
import {getFontCSS, PosterImageHandler} from "../actions/PosterImageHandler";
import Compressor from 'compressorjs';
import jwt_decode from "jwt-decode";

export interface PropsType {
    fontCSS: string;
}

const CustomisePoster = (props: PropsType) => {
    const appContext = React.useContext(AppContext);
    const navigate = useNavigate();
    const [posterTypeActive, setPosterTypeActive] = React.useState<number>(1);
    const [activePhotoIndex, setActivePhotoIndex] = useStateCallback<number>(0);
    const [childPhotoUrl, setChildPhotoUrl] = React.useState<string>('');
    const [childPhotoID, setChildPhotoID] = React.useState<number>(0);
    const [shareTextValue, setShareTextValue] = React.useState<string>('');
    const [shareTextLength, setShareTextLength] = React.useState<number>(0);
    const [updateShareTextTimer, setUpdateShareTextTimer] = React.useState<NodeJS.Timeout|null>(null);
    const [templateList, setTemplateList] = React.useState<any|null>(null);
    const [templateIndex, setTemplateIndex] = React.useState<number>(0);
    const inputFileEl = React.useRef<HTMLInputElement>(null);
    const textAreaShareTextEl = React.useRef<HTMLTextAreaElement>(null);
    const numOfPoster = 2;
    const maxLengthShareText = 1000;
    const poster1Ref = React.useRef<HTMLDivElement>(null);
    const poster2Ref = React.useRef<HTMLDivElement>(null);
    const [poster1Img, setPoster1Img] = React.useState<string>('');
    const [poster2Img, setPoster2Img] = React.useState<string>('');
    const [childPhotoPoster, setChildPhotoPoster] = useStateCallback<string>(DefaultChildPhoto1);
    const [scrollVal, setScrollVal] = React.useState<number>(0);
    const [stickyShareBottom, setStickyShareBottom] = React.useState<number>(0);
    const [isShareMorePopupOpen, setIsShareMorePopupOpen] = React.useState<boolean>(false);
    const [posterData, setPosterData] = useState<PosterDataInterface>({
        child_name: "John Lee",
        subject_name: "Math",
        num_bubbles: "1000",
        num_worksheets: "100",
        subject_pct: 80,
        expiry_date: 0
    });

    const [referralLink, setReferralLink] = useState<string>(encodeURI(process.env.REACT_APP_REFERRAL_LINK+"/"))
    const title = 'Geniebook Referral Program';

    React.useEffect(() => {
        const token = localStorage.getItem(JWT_TOKEN_VAR_NAME);
        if (token === null) navigate("/");
        else {
            PosterInfoHandler(token, (data: { poster_info: PosterDataInterface }) =>{
                setPosterData(data.poster_info);
            });

            const userData:any = jwt_decode(token)
            const name: string = userData.name.length > 15 ? userData.name.substring(0,15) : userData.name
            const RefLink = process.env.REACT_APP_REFERRAL_LINK+
                "?i="+userData.user_id+
                "&n="+name+
                "&m="+userData.mobile+
                "#lead_source=Parent%20Referral%20Product&campaign=Parent%20App%20Referral";
            setReferralLink(encodeURI(RefLink));

            window.scrollTo(0, 0);
            getChildPhoto(token!);
            getTemplateList(token!);
            getPhotoIndex(token!);
            getShareText(token!);
            handlePosterImage();
        }
    }, []);

    React.useEffect(()=>{handlePosterImage()}, [referralLink, poster2Img, posterData, props.fontCSS])

    React.useEffect(() => {
        const onScroll = () => setScrollVal(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    React.useEffect(() => {
        const onScroll = () => {
            setScrollVal(window.pageYOffset);
            setStickyBottom(window.pageYOffset);
        };
        // clean up code
        window.removeEventListener('resize', onScroll);
        window.addEventListener('resize', onScroll);
    });

    React.useEffect(() => {
        setStickyBottom(scrollVal);
    }, [scrollVal]);


    const handlePrevPoster = () => {
        let posterType = posterTypeActive;
        posterType--;
        if (posterType < 1) posterType = numOfPoster;
        setPosterTypeActive(posterType);
    }

    const handleNextPoster = () => {
        let posterType = posterTypeActive;
        posterType++;
        if (posterType > numOfPoster) posterType = 1;
        setPosterTypeActive(posterType);
    }

    const handleDeletePhoto = () => {
        // might need confirm message

        let token = appContext!.userData[JWT_TOKEN_VAR_NAME];
        
        let callback = (data:{result:any|null, error:string|null}) => {
            if (data.error !== null) {
                let messageProp:PopupContentInterface = {
                    title: ERROR_POPUP_TITLE,
                    message: data.error,
                    image: null,
                    withCloseButton: true,
                    isErrorPopup: true,
                }
                appContext?.setPopupContent(messageProp);
                appContext?.setIsPopupMessageOpen(true);
            } else {
                const result = data.result;
                if (result!.code !== 200) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: ERROR_MESSAGE_DEFAULT,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                } else if (result!.message === 'error' && result.payload === null) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: result.description,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                    // show message box error from result.description
                } else {
                    setChildPhotoUrl('');
                    setChildPhotoID(0);
                    setActivePhotoIndex(0, () => {handlePhotoIndex(0)});
                    setChildPhotoPoster(DefaultChildPhoto1, () => {handlePosterImage();});
                }
            }
        }
        const headers = {
            'Authorization': token
        }
        APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'parent-child-photo/' + childPhotoID, 'DELETE', headers, null, callback);
    }

    const handleInputFileOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files !== null && event.currentTarget.files.length > 0) {
            // check the file
            var file = event.currentTarget.files[0];

            //Size Checking
            if (file.size > 10000000) {
                let messageProp:PopupContentInterface = {
                    title: "Image Size",
                    message: "Image size is too large. Please upload a new image that is less than 10mb.",
                    image: null,
                    withCloseButton: true,
                    buttonText: 'Upload New Image',
                    isHTML: true,
                    isErrorPopup: true,
                    buttonCallback: handleUploadImageClick,
                }
                appContext?.setPopupContent(messageProp);
                appContext?.setIsPopupMessageOpen(true);
                return
            }

            //Format Checking
            if (!(file.type === "image/png" || file.type === "image/jpeg")) {
                let messageProp:PopupContentInterface = {
                    title: "Invalid Format",
                    message: "Please upload another image in .jpg or .png format.",
                    image: null,
                    withCloseButton: true,
                    buttonText: 'Upload New Image',
                    isHTML: true,
                    isErrorPopup: true,
                    buttonCallback: handleUploadImageClick,
                }
                appContext?.setPopupContent(messageProp);
                appContext?.setIsPopupMessageOpen(true);
                return
            }

            if (file.size > 1000000) {
                new Compressor(file, {
                    quality: 0.5,
                    success: (compressedResult) => {
                        UploadImage(compressedResult);
                    },
                    error(error: Error) {
                        let messageProp:PopupContentInterface = {
                            title: "Error",
                            message: "Image processing failure. Please check image and try uploading again. We apologize for the inconvenience.",
                            image: null,
                            withCloseButton: true,
                            buttonText: 'Upload New Image',
                            isHTML: true,
                            isErrorPopup: true,
                            buttonCallback: handleUploadImageClick,
                        }
                        appContext?.setPopupContent(messageProp);
                        appContext?.setIsPopupMessageOpen(true);
                    },
                });
            } else {
                UploadImage(event.currentTarget.files[0]);
            }
        }
    }

    const handleUploadImageClick = () => {
        inputFileEl.current!.click()
    }

    function UploadImage(file:File|Blob) {
        const formData = new FormData();

        formData.append('photo', file);
        let token = appContext!.userData[JWT_TOKEN_VAR_NAME];

        let callback = (data:{result:any|null, error:string|null}) => {
            if (data.error !== null) {
                let messageProp:PopupContentInterface = {
                    title: ERROR_POPUP_TITLE,
                    message: data.error,
                    image: null,
                    withCloseButton: true,
                    isErrorPopup: true,
                }
                appContext?.setPopupContent(messageProp);
                appContext?.setIsPopupMessageOpen(true);
            } else {
                const result = data.result;
                if (result!.code !== 200) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: ERROR_MESSAGE_DEFAULT,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                } else if (result!.message === 'error' && result.payload === null) {
                    let errorTitle = ERROR_POPUP_TITLE;
                    if (typeof result.error_title !== undefined) errorTitle = result.error_title;

                    let messageProp:PopupContentInterface = {
                        title: errorTitle,
                        message: result.description,
                        image: null,
                        withCloseButton: true,
                        buttonText: 'Upload New Image',
                        isHTML: true,
                        isErrorPopup: true,
                        buttonCallback: handleUploadImageClick,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                    // show message box error from result.description
                } else {
                    let payload = result.payload;
                    setChildPhotoUrl(payload.photo_file_url);
                    setChildPhotoID(payload.parent_child_photo_id);
                    setActivePhotoIndex(2, () => {handlePhotoIndex(2)});
                    setChildPhotoPoster(payload.photo_file_url, () => {handlePosterImage();});
                }
            }
            appContext!.setIsPopupLoadingOpen(false);
        }
        const headers = {
            'Authorization': token
        }
        appContext!.setLoadingTitle('Uploading image…');
        appContext!.setLoadingMessage('Please wait while we generate your customized poster!');
        appContext!.setIsPopupLoadingOpen(true);
        APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'parent-child-photo', 'POST', headers, formData, callback);
    }

    const handleShareTextOnInput = (e:React.ChangeEvent<HTMLTextAreaElement>) => {
        let share_text = e.target.value.slice(0, maxLengthShareText);
        setShareTextLength(share_text.length);
        setShareTextValue(share_text);
    }

    const handleShareTextOnChange = () => {
        if (updateShareTextTimer) {
            clearTimeout(updateShareTextTimer);
            setUpdateShareTextTimer(null);
        }
        setUpdateShareTextTimer(setTimeout(() => {
            UpdateShareText(textAreaShareTextEl.current!.value);
        }, 5000));
    }

    function UpdateShareText(shareText:string) {
        let token = appContext!.userData[JWT_TOKEN_VAR_NAME];
        let apiData = {
            share_text: shareText,
        };

        let callback = (data:{result:any|null, error:string|null}) => {
            if (data.error !== null) {
                let messageProp:PopupContentInterface = {
                    title: ERROR_POPUP_TITLE,
                    message: data.error,
                    image: null,
                    withCloseButton: true,
                    isErrorPopup: true,
                }
                appContext?.setPopupContent(messageProp);
                appContext?.setIsPopupMessageOpen(true);
            } else {
                const result = data.result;
                if (result!.code !== 200) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: ERROR_MESSAGE_DEFAULT,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                } else if (result!.message === 'error' && result.payload === null) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: result.description,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                    // show message box error from result.description
                } else {
                }
            }
        }
        const headers = {
            'Authorization': token
        }
        APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'referral-poster/create-update-share-text', 'POST', headers, JSON.stringify(apiData), callback);
    }

    const handleRegenerateSuggestions = () => {
        let share_text = templateList[templateIndex].template;
        share_text = share_text.replaceAll('{referralLink}', referralLink);
        share_text = share_text.replaceAll('\n', '\r\n');

        if(shareTextValue == share_text) {
            let share_text = templateList[(templateIndex+1)%templateList.length].template;
            share_text = share_text.replaceAll('{referralLink}', referralLink);
        }

        share_text = share_text.slice(0, maxLengthShareText);
        setShareTextLength(share_text.length);
        setShareTextValue(share_text);
        setTemplateIndex((templateIndex+1)%templateList.length);
        UpdateShareText(share_text);
    }

    function getShareText(token:string) {
        let callback = (data:{result:any|null, error:string|null}) => {
            if (data.error !== null) {
                let messageProp:PopupContentInterface = {
                    title: ERROR_POPUP_TITLE,
                    message: data.error,
                    image: null,
                    withCloseButton: true,
                    isErrorPopup: true,
                }
                appContext?.setPopupContent(messageProp);
                appContext?.setIsPopupMessageOpen(true);
            } else {
                const result = data.result;
                if (result!.code !== 200) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: ERROR_MESSAGE_DEFAULT,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                } else if (result!.message === 'error' && result.payload === null) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: result.description,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                    // show message box error from result.description
                } else {
                    let payload = result.payload;
                    if (payload !== '') {
                        setShareTextLength(payload.length);
                        setShareTextValue(payload);
                    } else {
                        let share_text = "Love Geniebook’s worksheets and classes. It really helps my child with revising and practicing! Sign up for a free trial using my referral link " + referralLink + " today and enjoy incentives!s worksheets and classes. It really helps my child with revising and practicing! Sign up for a free trial using my referral link " + referralLink + " today and enjoy incentives!";
                        share_text = share_text.slice(0, maxLengthShareText);
                        setShareTextLength(share_text.length);
                        setShareTextValue(share_text);
                    }
                }
            }
        }

        const headers = {
            'Authorization': token
        }
        APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'referral-poster/get-share-text', 'GET', headers, null, callback);
    }

    function getPhotoIndex(token:string) {
        const headers = {
            'Authorization': token
        }
        APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'referral-poster/get-photo-index', 'GET', headers, null, (data:{result:any|null, error:string|null}) => {
            if (data.error !== null) {
                let messageProp:PopupContentInterface = {
                    title: ERROR_POPUP_TITLE,
                    message: data.error,
                    image: null,
                    withCloseButton: true,
                    isErrorPopup: true,
                }
                appContext?.setPopupContent(messageProp);
                appContext?.setIsPopupMessageOpen(true);
            } else {
                const result = data.result;
                if (result!.code !== 200) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: ERROR_MESSAGE_DEFAULT,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                } else if (result!.message === 'error' && result.payload === null) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: result.description,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                    // show message box error from result.description
                } else {
                    let payload = result.payload;
                    setActivePhotoIndex(payload, () => {
                        switch (payload){
                            case 0 : {
                                setChildPhotoPoster(DefaultChildPhoto1, () => {handlePosterImage();});
                                break;
                            } case 1 : {
                                setChildPhotoPoster(DefaultChildPhoto2, () => {handlePosterImage();});
                                break;
                            } case 2 : {
                                const headers = {
                                    'Authorization': token
                                }
                                APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'parent-child-photo', 'GET', headers, null, (data:{result:any|null, error:string|null}) => {
                                    if (data.error !== null) {
                                        // show message box error
                                    } else {
                                        const result = data.result;
                                        if (result!.code !== 200) {
                                            // show message box error from result!.error_msg
                                        } else {
                                            let payload = result.payload;
                                            if (payload !== null) {
                                                setChildPhotoPoster(payload.photo_file_url, () => {handlePosterImage();});
                                            }
                                        }
                                    }
                                });
                                break;
                            } default : {
                                setChildPhotoPoster(DefaultChildPhoto1, () => {handlePosterImage();});
                                break;
                            }
                        }
                    });
                }
            }
        });
    }

    function getChildPhoto(token:string) {
        let callback = (data:{result:any|null, error:string|null}) => {
            if (data.error !== null) {
                let messageProp:PopupContentInterface = {
                    title: ERROR_POPUP_TITLE,
                    message: data.error,
                    image: null,
                    withCloseButton: true,
                    isErrorPopup: true,
                }
                appContext?.setPopupContent(messageProp);
                appContext?.setIsPopupMessageOpen(true);
            } else {
                const result = data.result;
                if (result!.code !== 200) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: ERROR_MESSAGE_DEFAULT,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                } else if (result!.message === 'error' && result.payload === null) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: result.description,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                    // show message box error from result.description
                } else {
                    let payload = result.payload;
                    if (payload !== null) {
                        setChildPhotoUrl(payload.photo_file_url);
                        setChildPhotoID(payload.parent_child_photo_id);
                    }
                }
            }
        }
        const headers = {
            'Authorization': token
        }
        APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'parent-child-photo', 'GET', headers, null, callback);
    }

    const handleDownloadPosters = () => {
        const link = document.createElement('a')
        link.download = 'poster-image.png'
        if (posterTypeActive === 1){
            link.href = poster1Img
        }
        if (posterTypeActive === 2){
            link.href = poster2Img
        }
        link.click();
        openToast(TOAST_TYPE_SUCCESS, 'Poster successfully saved to your device.');
    };

    const handlePosterImage = () => {
            PosterImageHandler(poster1Ref, props.fontCSS,(data : {poster_image: string}) => {
                setPoster1Img(data.poster_image)
            })

            PosterImageHandler(poster2Ref, props.fontCSS,(data: { poster_image: string }) => {
                setPoster2Img(data.poster_image)
            })
    }

    const handlePhotoIndex = (idx: number) => {
        const token = localStorage.getItem(JWT_TOKEN_VAR_NAME);
        if (token === null) navigate("/");

        const body = {
            "poster_photo_index": idx
        }
        const headers = {
            'Authorization': token
        }
        APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'referral-poster/create-update-photo-index', 'POST', headers, JSON.stringify(body), (data:{result:any|null, error:string|null}) => {
            if (data.error !== null) {
                let messageProp:PopupContentInterface = {
                    title: ERROR_POPUP_TITLE,
                    message: data.error,
                    image: null,
                    withCloseButton: true,
                    isErrorPopup: true,
                }
                appContext?.setPopupContent(messageProp);
                appContext?.setIsPopupMessageOpen(true);
            } else {
                const result = data.result;
                if (result!.code !== 200) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: ERROR_MESSAGE_DEFAULT,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                } else if (result!.message === 'error' && result.payload === null) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: result.description,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                    // show message box error from result.description
                }
            }
        });
    }

    const handleChangePosterImage = (idx: number) => {
        setActivePhotoIndex(idx, ()=>{
            handlePhotoIndex(idx)
        })

        if (idx === 0) {
            setChildPhotoPoster(DefaultChildPhoto1, () => {
                handlePosterImage();
            })
        }

        if (idx === 1) {
            setChildPhotoPoster(DefaultChildPhoto2, () => {
                handlePosterImage();
            })
        }

        if (idx === 2) {
            setChildPhotoPoster(childPhotoUrl, () => {
                handlePosterImage();
            })
        }
    }

    const handleShareButtonOnClick = () => {
        if (appContext!.userData === null) {
            appContext!.setIsPopupLoginOpen(true)
        }
    }

    function copyToClipboard(text:string, textDesc:string) {
        navigator.clipboard.writeText(text)
        openToast(TOAST_TYPE_SUCCESS, textDesc + ' copied to clipboard.');
    }

    function openToast(toastType = TOAST_TYPE_DEFAULT, toastMessage1 = '', toastMessage2 = '', toastButtonText = TOAST_DEFAULT_BUTTON_TEXT, toastCallback = null) {
        appContext?.setIsToastOpen(true);
        appContext?.setToastCallback(toastCallback);
        appContext?.setToastButtonText(toastButtonText);
        appContext?.setToastType(toastType);
        appContext?.setToastMessage1(toastMessage1);
        appContext?.setToastMessage2(toastMessage2);
    }
    
    function getTemplateList(token:string) {
        let callback = (data:{result:any|null, error:string|null}) => {
            if (data.error !== null) {
                let messageProp:PopupContentInterface = {
                    title: ERROR_POPUP_TITLE,
                    message: data.error,
                    image: null,
                    withCloseButton: true,
                    isErrorPopup: true,
                }
                appContext?.setPopupContent(messageProp);
                appContext?.setIsPopupMessageOpen(true);
            } else {
                const result = data.result;
                if (result!.code !== 200) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: ERROR_MESSAGE_DEFAULT,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                } else if (result!.message === 'error' && result.payload === null) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: result.description,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                    // show message box error from result.description
                } else {
                    setTemplateList(result.payload);
                }
            }
        }
        const headers = {
            'Authorization': token
        }
        APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'referral-poster/template-list', 'GET', headers, null, callback);
    }

    function setStickyBottom(scrollValue:number) {
        // bottom = footer height (118) - (total_document_height - viewport_height - scroll_position)
        let bottom = 118 - (appContext!.appRef.current!.clientHeight - window.innerHeight - scrollValue) - 10;
        if (bottom < -10) bottom = -10;
        setStickyShareBottom(bottom);
    }

    return (
        <>
            <div className="customise-poster-content">
                <p className="customise-poster-title">Customise and share your poster!</p>
                <div className="poster-selection">
                    <p className="title max-lg:hidden">Poster selection</p>
                    <div className="poster-container">
                        <div className="poster-nav poster-nav-prev" onClick={handlePrevPoster}><img src={IconChevronLeft} /></div>
                        <div className="poster-slider">
                            <div className={"poster poster-1" + (posterTypeActive !== 1 ? " hidden" : "")}>
                                <div className="hidden text-left">
                                    <Poster1 ref={poster1Ref} getPhotoSrc={childPhotoPoster} qrShareLink={referralLink}/>
                                </div>
                                <img className="rounded-[10px]" src={poster1Img === '' ? DefaultPoster1 : poster1Img} alt="Image Poster 1"/>
                            </div>
                            <div className={"poster poster-2" + (posterTypeActive !== 2 ? " hidden" : "")}>
                                <div className="hidden text-left">
                                    <Poster2 ref={poster2Ref} getPhotoSrc={childPhotoPoster} qrShareLink={referralLink} posterData={posterData}/>
                                </div>
                                <img className="rounded-[10px]" src={poster2Img === '' ? DefaultPoster2 : poster2Img} alt="Image Poster 2"/>
                            </div>
                        </div>
                        <div className="poster-nav poster-nav-next" onClick={handlePrevPoster}><img src={IconChevronRight} /></div>
                    </div>
                    <div className="poster-selection-nav">
                        <img src={IconChevronLeft} className="nav nav-prev" onClick={handlePrevPoster} />
                        <div className="nav-points">
                            {
                                Array.from(Array(numOfPoster), (e, i) => {
                                    return <div className={"nav-point" + (posterTypeActive === (i + 1) ? " active" : "")} key={i} onClick={() => {setPosterTypeActive(i + 1)}}></div>
                                })
                            }
                        </div>
                        <img src={IconChevronRight} className="nav nav-next" onClick={handleNextPoster} />
                    </div>
                </div>
                <div className="child-picture-share">
                    <p className="title">Change your picture</p>
                    <p className="child-picture-note">Upload a picture of your child to personalise this referral poster and increase your chances of doing a successful referral! (Maximum file size: 10 MB)</p>
                    <div className="upload-photo-container">
                        <div className={"button-child-photo overflow-hidden " + (activePhotoIndex === 0 ? ' active' : '')} onClick={() => {handleChangePosterImage(0)}}>
                            <div className='child-photo-container'>
                                <img src={DefaultChildPhoto1} className="photo" />
                            </div>
                        </div>
                        <div className={"button-child-photo overflow-hidden" + (activePhotoIndex === 1 ? ' active' : '')} onClick={() => {handleChangePosterImage(1)}}>
                            <div className='child-photo-container'>
                                <img src={DefaultChildPhoto2} className="photo" />
                            </div>
                        </div>
                        <div className={"button-child-photo button-upload" + (activePhotoIndex === 2 ? ' active' : '')} onClick={() => {(childPhotoUrl === '' ? inputFileEl.current!.click() : handleChangePosterImage(2))}}>
                            <div className={'child-photo-container' + (childPhotoUrl !== '' ? ' photo-container' : ' icon-container')}>
                                {childPhotoUrl === '' && <img src={IconPlus} className="icon" />}
                                {childPhotoUrl !== '' && <img src={childPhotoUrl} className="photo" />}
                            </div>
                            {childPhotoUrl !== '' && <div className="btn-delete-child-photo" onClick={handleDeletePhoto}><img src={IconDelete} /></div>}
                        </div>
                        <div className="absolute right-0 button-container center-vertical max-md:hidden">
                            <div id="tracker-download" className="overflow-hidden text-center transition cursor-pointer button button-orange button-shadow w-[190.5px] h-[54px] max-lg:w-[254px] max-lg:h-[72px]" onClick={handleDownloadPosters}>
                                <div className="button_bg_hover"></div>
                                <div className="button_content text-[18px] text-campton font-bold relative leading-[54px] max-lg:text-[24px] max-lg:leading-[72px]">Download poster</div>
                            </div>
                            <div className="button_bg_disabled"></div>
                        </div>
                    </div>
                    <div className="share-text-title" onClick={handleRegenerateSuggestions}>
                        <p className="float-left title">Share with text</p>
                        <p className="regenerate-suggestions"><img src={IconReload} /> Regenerate<span className="max-md:hidden"> suggestions</span></p>
                    </div>
                    <div className="share-text-input">
                        <textarea ref={textAreaShareTextEl} value={shareTextValue} onInput={handleShareTextOnInput} onChange={handleShareTextOnChange}></textarea>
                        <p className="letter-counter">{shareTextLength}/{maxLengthShareText}</p>
                    </div>
                    <div className="copy-text-share-container">
                        <div className="absolute left-0 button-container md:center-vertical max-md:middle-div" onClick={() => {copyToClipboard(shareTextValue, "Share Text")}}>
                            <div id="tracker-copy-link" className="overflow-hidden text-center transition cursor-pointer button button-orange button-shadow w-[90.8025px] h-[33.75px] max-lg:w-[121.06px] max-lg:h-[45px] max-md:h-[31.6px]">
                                <div className="button_bg_hover"></div>
                                <div className="button_content text-[11.25px] text-campton font-bold relative leading-[33.75px] max-lg:text-[15px] max-lg:leading-[45px] max-md:leading-[31.6px] max-md:font-extrabold max-md:text-[15px]">Copy<span className="max-md:hidden"> Text</span></div>
                            </div>
                            <div className="button_bg_disabled"></div>
                        </div>

                        <div className="share-buttons">
                            <button id="tracker-copy-link" className="copy-link" onClick={() => {copyToClipboard(referralLink, "Referral Link")}}><img src={IconLink} /></button>

                            <WhatsappShareButton id="tracker-share-whatsapp" url={shareTextValue.includes("http") ? " " : referralLink} title={shareTextValue} resetButtonStyle={false} openShareDialogOnClick={appContext!.userData !== null} onClick={() => {handleShareButtonOnClick()}}>
                                <WhatsappIcon size={34} round bgStyle={{fill: '#F7941E'}} />
                            </WhatsappShareButton>

                            <FacebookShareButton id="tracker-share-facebook" url={referralLink} title={title} quote={shareTextValue} hashtag={'#Geniebook'} resetButtonStyle={false} openShareDialogOnClick={appContext!.userData !== null} onClick={() => {handleShareButtonOnClick()}}>
                                <FacebookIcon size={39.75} round bgStyle={{fill: '#F7941E'}} />
                            </FacebookShareButton>

                            <LinkedinShareButton id="tracker-share-linkedin" url={referralLink} title={title} summary={shareTextValue} resetButtonStyle={false} openShareDialogOnClick={appContext!.userData !== null} onClick={() => {appContext!.userData === null ? appContext!.setIsPopupLoginOpen(true) : appContext!.setIsPopupLoginOpen(false)}}>
                                <LinkedinIcon size={39.75} round bgStyle={{fill: '#F7941E'}} />
                            </LinkedinShareButton>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sticky-share-button" style={{bottom: stickyShareBottom}}>
                <div className="w-full button-container" onClick={() => {setIsShareMorePopupOpen(true)}}>
                    <div className="overflow-hidden text-center transition cursor-pointer button button-orange button-shadow w-full h-[45px]">
                        <div className="button_bg_hover"></div>
                        <div className="button_content text-[15px] text-campton font-extrabold relative leading-[45px]">Share</div>
                    </div>
                </div>
            </div>

            <ShareMorePopup isPopupOpen={isShareMorePopupOpen} setIsPopupOpen={setIsShareMorePopupOpen} popupTitle="Other Sharing Options" referralLink={referralLink} linkTitle={title} shareTextValue={shareTextValue} downloadPoster={handleDownloadPosters} copyLink={() => {copyToClipboard(referralLink, "Referral Link")}}/>
            <input type="file" ref={inputFileEl} className="hidden" accept="image/png, image/jpeg" onChange={handleInputFileOnChange} />
        </>
    );
}
 
export default CustomisePoster;