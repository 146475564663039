import { useState } from 'react';
import IconChevronUp from '../images/icon-chevron-up.svg';

const FAQ = () => {
    const [faqList, setFAQList] = useState([
        {
            'number': 1,
            'title': 'How do I access the referral homepage as a referrer?',
            'content': 'To access the referral homepage as a referrer, you need to log in with your Geniebook Parent ID credentials. If you\'re not logged in, you\'ll be prompted to do so before you can perform any actions on the webpage. Once you\'ve logged in, you\'ll be taken to the referral homepage where you can access other content.',
            'isOpen': 1
        },
        {
            'number': 2,
            'title': 'What should I do if I forget my password?',
            'content': 'If you forget your password, click on "Forgot Password" and enter the email address associated with your Geniebook account. A reset password link will be sent to the email address. If you can\'t remember your email address, please contact your Education Consultant.',
            'isOpen': 0
        },
        {
            'number': 3,
            'title': 'How do I refer another parent to Geniebook?',
            'content': 'You can either share the referral link or referral poster to the parent you want to refer to. Incase of referral link, upon clicking on it, the referee would have to fill in their contact details and later an Education Consultant will be in touch with them. Incase of the poster, the referee would need to scan the QR code on the poster and fill the form in the same way as the referral link.',
            'isOpen': 0
        },
        {
            'number': 4,
            'title': 'How will a referee submit their details via referral link?',
            'content': 'For a referee to submit their details, they will need to click on the referral link. This will open a form in which your name and mobile number will be automatically filled in, and the referee will need to enter their name, mobile number, email address (optional), and level of their child. After submitting the form, a message will appear indicating that your education consultant will get in touch with them regarding the Geniebook subscription.',
            'isOpen': 0
        },
        {
            'number': 5,
            'title': 'What does it mean to initiate a referral?',
            'content': 'Once a referee submits their details via the referral link, we will internally validate if the details are valid and unique. If found valid and unique, the referral will be initiated.',
            'isOpen': 0
        },
        {
            'number': 6,
            'title': 'How will I receive the referral initiation reward of 500 bubbles?',
            'content': 'Once the referee has filled their details in the referral form, our system will verify if the details are valid and unique. If all correct, then your referral will be initiated and you\'ll receive the 500 bubbles automatically in your Geniebook parent account. Please note that this is a one-time reward.',
            'isOpen': 0
        },
        {
            'number': 7,
            'title': 'How will I receive the successful referral cash prize ranging from $70 to $100?',
            'content': 'If a referee successfully signs up for any Geniebook subscription, you will have to wait for their 7-day refund period to be over to be eligible for the reward. Once it\'s over, our team will transfer the amount to your bank account within 1-2 working days. You can also choose to redeem this reward in the form of bubbles, for example, if you\'re eligible for a reward worth $70, you can get 7000 bubbles instead of the cash.',
            'isOpen': 0
        },
        {
            'number': 8,
            'title': 'How can I track the progress of referrals I\'ve initiated?',
            'content': 'To track the progress of referrals you\'ve initiated, in the homepage, go to the "Active Referrals" tab. Here you\'ll be able to see the status of your referrals and track their progress.',
            'isOpen': 0
        },
        {
            'number': 9,
            'title': 'How can I use a poster for my referrals?',
            'content': 'Yes, as a referrer, you can choose from our two poster templates, customise and download them as a photo. The first poster template contains basic details about Geniebook, and the second one contains details about your child\'s learning journey with Geniebook: child\'s name, level, worksheet count data, bubbles earned and progress percentage. You can choose either of these poster templates or can share the referral link directly without any poster.',
            'isOpen': 0
        },
        {
            'number': 10,
            'title': 'How do I customise my referral poster?',
            'content': 'To customise your referral poster, from the homepage, you\'ll need to click on the link that says “Click here to customise your poster”. Clicking on this link will take you to the poster customization page where you can upload a picture of your child. The picture needs to be of less than 12 MB in size. You can also type in a personalised referral message, download the poster, and share it on social media.',
            'isOpen': 0
        },
        {
            'number': 11,
            'title': 'How do I maximise my chances of a successful referral?',
            'content': 'We recommend downloading and sharing the referral poster in your social media groups or upload status. From the poster, the interested parents can scan the QR code to fill the referral form. We also recommend uploading your child\'s image in the poster to build trust. You can also use the poster template with your child\'s progress to share the value that you have received while using Geniebook.',
            'isOpen': 0
        },
    ]);

    function handleTogleFAQ(faqNumber:number) {
        let faq = [...faqList];
        for (let i = 0; i < faq.length; i++) {
            if (faq[i].number === faqNumber) {
                faq[i].isOpen = (faq[i].isOpen === 1 ? 0 : 1);
                break;
            }
        }
        setFAQList(faq);
    }

    return (
        <>
            <div className="faq-container">
                <p className="faq-page-title">Frequently Asked Questions</p>
                {
                    faqList?.map((faq) => (
                        <div className="faq-item" key={faq.number.toString()}>
                            <div className={"faq-title" + (faq.isOpen === 1 ? ' open' : '')} onClick={() => {handleTogleFAQ(faq.number)}}>
                                <p>{faq.title}</p>
                                <img src={IconChevronUp} />
                            </div>
                            <div className={"faq-content-container" + (faq.isOpen === 1 ? ' open' : '')}>
                                <div className="faq-content">
                                    <p>{faq.content}</p>
                                </div>
                            </div>
                        </div>
                    )) 
                }
            </div>
        </>
    );
}
 
export default FAQ;