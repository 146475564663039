import Popup from 'reactjs-popup';
import React from "react";

interface PopupWrapperInterface {
    isPopupOpen: boolean;
    setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
    children: JSX.Element;
    isNested: boolean;
    withCloseButton: boolean;
}

const PopupWrapper = (prop:PopupWrapperInterface) => {

    const handleClosePopup = () => { prop.setIsPopupOpen(false) }

    return (
        // There is an issue for nested popup. 
        // Parent popup will close if the user release a click outside the parent popup, even if the click starts inside the popup
        <Popup open={prop.isPopupOpen} onClose={handleClosePopup} className="popup-overlay" modal nested={prop.isNested} lockScroll>
            <div className="popup-card">
                <div className="relative bg-white rounded-lg shadow">
                    { 
                        (prop.withCloseButton) ? 
                        <svg className="popup-close-button" onClick={handleClosePopup}></svg>
                        : ""
                    }
                    { prop.children }
                </div>
            </div>
        </Popup>
    );
}
 
export default PopupWrapper;