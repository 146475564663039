import { FacebookShareButton, LinkedinShareButton, WhatsappShareButton } from "react-share";
import PopupWrapper from "./PopupWrapper";
import IconLink from '../images/icon-link.svg';
import IconDownload from '../images/icon-download.svg';
import IconWhatsapp from '../images/social-media-logo/icon-whatsapp.svg';
import IconFacebook from '../images/social-media-logo/icon-facebook.svg';
import IconLinkedin from '../images/social-media-logo/icon-linkedin.svg';

interface propType {
    isPopupOpen: boolean;
    setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
    popupTitle: string;
    referralLink: string;
    linkTitle: string;
    shareTextValue: string;
    downloadPoster: () => void;
    copyLink: () => void;
}

const ShareMorePopup = (props:propType) => {
    let textBeforeLink = props.shareTextValue;
    let textLink = "";
    let textAfterLink = "";

    if(props.shareTextValue != "") {
        let fullTextArray1 = props.shareTextValue.split("http");
        if(fullTextArray1.length >= 2){ // Check if text has link
            let fullTextArray2 = fullTextArray1[1].split(" ");
            textBeforeLink = fullTextArray1[0];
            textLink = "http"+fullTextArray2[0];
            if(fullTextArray2.length >= 2) { // Check if text has somethign after the link
                fullTextArray2.shift();
                textAfterLink = fullTextArray2.join(" ");
            }
        }
    }

    return (
        <PopupWrapper isPopupOpen={props.isPopupOpen} setIsPopupOpen={props.setIsPopupOpen} isNested={false} withCloseButton={true}>
            <>
            <p className="text-left text-primary py-[24px] font-black text-[24px] leading-[27px] max-w-[237px] mx-7">
                {props.popupTitle}
            </p>
            <div className="mx-7 pb-[27px]">
                <p className="text-primary font-bold text-[13.5px] text-left">Message preview:</p>
                <p className="text-primary mt-[6px] text-left text-[12px] break-words whitespace-normal max-h-[200px] overflow-y-auto">
                    {textBeforeLink}
                    <a className="text-[#F7941E] underline break-all" href={textLink} target="_blank"> {textLink} </a>            
                    {textAfterLink}
                </p>                
                <div className="share-container-popup">
                  <WhatsappShareButton id="tracker-share-whatsapp" url={props.shareTextValue.includes("http") ? " " : props.referralLink} title={props.shareTextValue} resetButtonStyle={false}>
                    <p className="inline-block text-white text-[18px] font-campton font-extrabold pointer-events-none"><img id="wa-icon" src={IconWhatsapp} /> Whatsapp</p>
                  </WhatsappShareButton>
    ​
                  <FacebookShareButton id="tracker-share-facebook" url={props.referralLink} title={props.linkTitle} quote={props.shareTextValue} hashtag={'#Geniebook'} resetButtonStyle={false}>
                    <p className="inline-block text-white text-[18px] font-campton font-extrabold pointer-events-none"><img id="fb-icon" src={IconFacebook} /> Facebook</p>
                  </FacebookShareButton>
  ​
                  <LinkedinShareButton id="tracker-share-linkedin" url={props.referralLink} title={props.linkTitle} summary={props.shareTextValue} resetButtonStyle={false}>
                  <p className="inline-block text-white text-[18px] font-campton font-extrabold pointer-events-none"><img id="li-icon" src={IconLinkedin} /> LinkedIn</p>
                  </LinkedinShareButton>

                  <div id="tracker-copy-link" className="w-full overflow-hidden text-center transition cursor-pointer button button-orange button-shadow h-[45px] mt-[12px] hover:scale-y-[1.1] hover:scale-x-[1]"  onClick={props.copyLink}>
                    <div className="button_bg_hover"></div>
                    <div className="button_content text-[18px] text-campton font-bold relative leading-[45px]"><img id="copy-icon" src={IconLink} /> Copy Link</div>
                  </div>

                  <div id="tracker-download" className="w-full overflow-hidden text-center transition cursor-pointer button button-orange button-shadow h-[45px] mt-[15px] hover:scale-y-[1.1] hover:scale-x-[1]" onClick={props.downloadPoster}>
                    <div className="button_bg_hover"></div>
                    <div className="button_content text-[18px] text-campton font-bold relative leading-[45px]"><img id="download-icon" src={IconDownload} /> Download Poster</div>
                  </div>
                </div>
            </div>
            </>
        </PopupWrapper>
    );
}
 
export default ShareMorePopup;