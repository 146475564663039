import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import "./App.css";
import { useState, useEffect, useRef } from "react";
import Root from "./pages/root";
import Header from "./components/Header";
import Toast from "./components/Toast";
import Footer from "./components/Footer";
import MessagePopup from "./components/MessagePopup";
import Loading from "./components/Loading";
import AppContextInterface from "./interfaces/AppContextInterface";
import PopupContentInterface from "./interfaces/PopupContentInterface";
import AppContext from "./components/AppContext";
import LoginPopup from "./components/LoginPopup";
import ConfirmPopup from "./components/ConfirmPopup";
import jwt_decode from "jwt-decode";
import {
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_LOADING_TITLE,
  JWT_TOKEN_VAR_NAME,
  TOAST_DEFAULT_BUTTON_TEXT,
  TOAST_TYPE_DEFAULT,
} from "./actions/Constants";
import CustomisePoster from "./pages/CustomisePoster";
import FAQ from "./pages/FAQ";
import Error404 from "./pages/Error404";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";
import * as React from "react";
import { getFontCSS } from "./actions/PosterImageHandler";
import ReferralMobile from "./pages/ReferralMobile";
import YourInvitesAndRewards from "./pages/referral-mobile/YourInvitesAndRewards";
import moment from "moment";

export interface PropsType {
  fontCSS: string;
  isWebClosed: boolean;
}

const App = (props: PropsType) => {
  let routes = useRoutes([
    { path: "/", element: <Root fontCSS={props.fontCSS} isWebClosed={props.isWebClosed} /> },
    {
      path: "customise-poster",
      element: <CustomisePoster fontCSS={props.fontCSS} />,
    },
    { path: "faq", element: <FAQ /> },
    //! temporary only. until token is ready, will skip token checking
    {
      path: `referralMobile/:token`,
      element: <ReferralMobile />,
    },
    {
      path: "referralMobile/reward-list/:token",
      element: <ReferralMobile />,
    },
    { path: "404", element: <Error404 /> },
    { path: "*", element: <Error404 /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  const [userData, setUserData] = useState<any | null>(null);
  const [isPopupLoginOpen, setIsPopupLoginOpen] = useState<boolean>(false);
  const [isPopupLoadingOpen, setIsPopupLoadingOpen] = useState<boolean>(false);
  const [loadingTitle, setLoadingTitle] = useState<string>(
    DEFAULT_LOADING_TITLE
  );
  const [loadingMessage, setLoadingMessage] = useState<string>(
    DEFAULT_LOADING_MESSAGE
  );
  const [isPopupMessageOpen, setIsPopupMessageOpen] = useState<boolean>(false);
  const [isPopupConfirmOpen, setIsPopupConfirmOpen] = useState<boolean>(false);
  const [popupContent, setPopupContent] = useState<
    Array<PopupContentInterface>
  >([]);
  const [isSessionLoaded, setIsSessionLoaded] = useState<boolean>(false);
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [toastType, setToastType] = useState<number>(TOAST_TYPE_DEFAULT);
  const [toastMessage1, setToastMessage1] = useState<string>("");
  const [toastMessage2, setToastMessage2] = useState<string>("");
  const [toastButtonText, setToastButtonText] = useState<string>(
    TOAST_DEFAULT_BUTTON_TEXT
  );
  const [toastCallback, setToastCallback] = useState<(() => void) | null>(null);
  const [fontCSS, setFontCSS] = React.useState<string>("");
  const appRef = useRef<HTMLDivElement>(null);
  const [isWebClosed, setIsWebClosed] = React.useState<boolean>(true);
  const phase2StartDate = new Date(2023, 9, 9, 0, 0, 0);
  let nowMoment = moment(new Date()); // Now Time in local Timezone

  useEffect(() => {
    const token = localStorage.getItem(JWT_TOKEN_VAR_NAME);
    if (token !== null) {
      let sessionData: any = jwt_decode(token);
      sessionData[JWT_TOKEN_VAR_NAME] = token;

      // check if token is expired
      const currentTime = Math.floor(Date.now() / 1000);
      if (sessionData["token_expiry"] < currentTime)
        localStorage.removeItem(JWT_TOKEN_VAR_NAME);
      else setUserData(sessionData);

      setIsSessionLoaded(true);
    }
    TagManager.initialize({ gtmId: "GTM-KZ8P6V9" });
    ReactGA.initialize([{ trackingId: "G-XRR18CNTVX" }]);
  }, []);

  useEffect(() => {
    if (isToastOpen) {
      setTimeout(() => {
        setIsToastOpen(false);
      }, 5000);
    }
  }, [isToastOpen]);

  React.useEffect(() => {
    getFontCSS(appRef).then((fontCSS) => {
      setFontCSS(fontCSS);
    });
  }, []);

  React.useEffect(() => {
      // for detecting timezone offset https://stackoverflow.com/a/8090836
      const timezone = parseInt(
          (-(new Date().getTimezoneOffset() / 60)).toString(),
      );
      nowMoment.hour(nowMoment.hour() - timezone + 8); // Now Time in GMT+8
      setIsWebClosed(nowMoment.toDate().getTime() > phase2StartDate.getTime());
  }, []);
  
  useEffect(() => {
    if (window.location.href.indexOf("referralMobile") === -1 && isWebClosed) {
      document.getElementsByTagName('body')[0].classList.add('closed-page');
    }
  }, [isWebClosed]);

  const appSetting: AppContextInterface = {
    appRef,
    userData,
    setUserData,
    isPopupLoginOpen,
    setIsPopupLoginOpen,
    isPopupMessageOpen,
    setIsPopupMessageOpen,
    isPopupConfirmOpen,
    setIsPopupConfirmOpen,
    popupContent,
    setPopupContent,
    setToastType,
    setIsToastOpen,
    setToastMessage1,
    setToastMessage2,
    setToastButtonText,
    setToastCallback,
    isPopupLoadingOpen,
    setIsPopupLoadingOpen,
    loadingTitle,
    setLoadingTitle,
    loadingMessage,
    setLoadingMessage,
    isSessionLoaded,
  };

  return (
    <AppContext.Provider value={appSetting}>
      <Router>
        <div ref={appRef} className="App">
          {window.location.href.indexOf("referralMobile") === -1 && <Header isWebClosed={isWebClosed} />}
          <div className={`lg:content`}>
            <App fontCSS={fontCSS} isWebClosed={isWebClosed} />
          </div>
          {window.location.href.indexOf("referralMobile") === -1 && !isWebClosed && <Footer />}
        </div>
        <Toast
          toastType={toastType}
          isToastOpen={isToastOpen}
          toastMessage1={toastMessage1}
          toastMessage2={toastMessage2}
          toastButtonText={toastButtonText}
          toastCallback={toastCallback}
        />
        {!userData && <LoginPopup />}
        <Loading />
        <MessagePopup />
      </Router>
    </AppContext.Provider>
  );
};

export default AppWrapper;
