import React, {forwardRef, Ref, useEffect} from 'react';
import { QRCode } from 'react-qrcode-logo';
import logo from "../images/poster/Logo_other variation_2-03.svg";
import geniesmart from "../images/poster/Poster_GenieSmart.svg";
import genieask from "../images/poster/Poster_GenieAsk.svg";
import genieclass from "../images/poster/Poster_GenieClass.svg";

export interface PropsType {
    getPhotoSrc: string;
    qrShareLink: string;
}

const Poster1 = forwardRef((props: PropsType, ref: Ref<HTMLDivElement>) => {

    useEffect(() => {},[props.getPhotoSrc, props.qrShareLink])

    return (
        <>
            <div ref={ref}>
                {/* DOM nodes you want to convert to PNG */}
                <div className="static h-[1600px] w-[1200px] bg-[#28265D]">
                    <div className="absolute">
                        <img className="pt-[62px] pl-[70px]" src={logo} style={{height: "auto", width: "374px"}} alt=""/>
                        <div className="pt-[48px] pl-[70px]">
                            <h1 className="h-[111px] font-black text-[93px] text-[#27AAE1]">Learn Smarter.</h1>
                            <h1 className="h-[111px] font-black text-[93px] text-[#27AAE1]">Do Better.</h1>
                        </div>
                        <div className="pt-[13px] pl-[70px]">
                            <p className="h-[96px] w-[1001px] font-black text-[40px] text-[#FFFFFF] leading-[48px]">Trusted by 220,000 users to improve their child’s results in English, Maths, Science and Chinese</p>
                        </div>
                        <div className="pt-[21px] pl-[70px]">
                            <p className="h-[48px] font-black text-[40px] text-[#FFFFFF]">From Primary 1 to Secondary 4</p>
                        </div>
                    </div>
                    <div className="absolute top-[603px] left-[690px] w-[510px] h-[678px] overflow-hidden">
                        <div className="w-[678px] h-[678px] rounded-[50%] overflow-hidden">
                            <img className="inline w-auto h-[678px] mx-auto ml-[0px]" src={props.getPhotoSrc} alt='Image Photo'/>
                        </div>
                    </div>

                    <div>
                        <div className="absolute top-[661px] left-[70px] bg-[#27AAE1] rounded-bl-[50px] rounded-tr-[100px]" style={{height: "143px", width: "593px"}}></div>
                        <img className="absolute top-[602px] left-[70px]" src={geniesmart} style={{height: "auto", width: "329px"}} alt=""/>
                        <p className="absolute top-[698px] left-[105px] text-left h-[82px] w-[501px] font-black text-[34px] text-[#ffffff] leading-[41px]">AI-personalised worksheets that identify learning gaps</p>
                    </div>

                    <div>
                        <div className="absolute top-[885px] left-[70px] bg-[#27AAE1] rounded-bl-[50px] rounded-tr-[100px]" style={{height: "143px", width: "593px"}}></div>
                        <img className="absolute top-[826px] left-[70px]" src={genieask} style={{height: "auto", width: "329px"}} alt=""/>
                        <p className="absolute top-[921px] left-[105px] text-left h-[82px] w-[535px] font-black text-[34px] text-[#ffffff] leading-[41px]">Chat anytime with teachers for doubts or homework help</p>
                    </div>

                    <div>
                        <div className="absolute top-[1109px] left-[70px] bg-[#27AAE1] rounded-bl-[50px] rounded-tr-[100px]" style={{height: "143px", width: "593px"}}></div>
                        <img className="absolute top-[1051px] left-[70px]" src={genieclass} style={{height: "auto", width: "329px"}} alt=""/>
                        <p className="absolute top-[1145px] left-[105px] text-left h-[82px] w-[535px] font-black text-[34px] text-[#ffffff] leading-[41px]">Live Lessons Based On MOE’s Latest Syllabus</p>
                    </div>

                    <div className="pt-[1303px] pl-[70px]">
                        <div className="absolute bg-[#FFFFFF] rounded-[34px]" style={{height: "233px", width: "236px"}}></div>
                        <div className="absolute top-[1336px] left-[306px] bg-[#F49021] rounded-tr-[100px] rounded-br-[100px]" style={{height: "168px", width: "825px"}}></div>
                        <p className="absolute top-[1365px] left-[366px] h-[108px] w-[688px] text-left font-black text-[45px] text-[#FFFFFF] leading-[54px]">Scan this QR code and receive $70 cash back upon signing up!</p>
                        <div className="absolute top-[1320px] left-[90px]">
                            <QRCode ecLevel={"L"} value={props.qrShareLink} size={180}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default Poster1;