import React, { useEffect, useState } from 'react';
import AppContext from '../components/AppContext';
import { useNavigate, useParams } from 'react-router';
import PopupContentInterface from '../interfaces/PopupContentInterface';
import {
  DEVICE_WEB,
  ERROR_MESSAGE_DEFAULT,
  ERROR_POPUP_TITLE,
  INIT_REWARD_ID,
  REFERRAL_MOBILE_STEP_MAIN_PAGE,
  REFERRAL_MOBILE_STEP_REWARD_LIST,
} from '../actions/Constants';
import { APIRequest } from '../actions/APIRequest';
import '../ReferralMobile.css';
import Preloading from '../components/Preloading';
import Error404 from './Error404';
import MainPage from './referral-mobile/MainPage';
import YourInvitesAndRewards from './referral-mobile/YourInvitesAndRewards';

export function getRefereeName(item:ParentReferralReward, rewardList:ParentReferralReward[]) {
  if (item.prize_id === INIT_REWARD_ID) {
    let sortedRewardList:ParentReferralReward[] = JSON.parse(JSON.stringify(rewardList));
    sortedRewardList.sort((a, b) => {
      if (a.date_created < b.date_created) return -1;
      if (a.date_created > b.date_created) return 1;
      return 0;
    });
    
    for (let i = 0; i < sortedRewardList.length; i++) {
      if (sortedRewardList[i].prize_id + '' !== INIT_REWARD_ID + '' && sortedRewardList[i].referee_name !== '') {
        return sortedRewardList[i].referee_name;
      }
    }
  } else if (item.referee_name !== '') {
    return item.referee_name;
  }
  return '';
}

export interface ParentReferralReward {
  date_created: string;
  date_delivered: string;
  date_redeemed: string;
  date_updated: string;
  ec_remark: string;
  owner_admin_id: number;
  parent_info_id: number;
  parent_referral_reward_id: number;
  prize_id: number;
  referral_progress_id: null | number;
  referee_name: string;
  reward_admin_id: null | number;
  reward_description: string;
  status_delivered: boolean;
  status_redeemed: boolean;
  version_reward: number;
}

const ReferralMobile = () => {
  const appContext = React.useContext(AppContext);
  let { token } = useParams();
  const navigate = useNavigate();
  const [step, setStep] = React.useState<number>(
    window.location.href.indexOf('referralMobile/reward-list') !== -1
      ? REFERRAL_MOBILE_STEP_REWARD_LIST
      : REFERRAL_MOBILE_STEP_MAIN_PAGE
  );
  const [validated, setValidated] = React.useState<boolean | null>(null);
  const [rewardList, setRewardList] = React.useState<ParentReferralReward[]>(
    []
  );
  const containerEl = React.useRef<HTMLDivElement>(null);
  const [scrollTopVal, setScrollTopVal] = useState<number>(0);
  const pathname = window.location.pathname;
  const [currentUrl, setCurrentUrl] = React.useState(pathname)
  React.useEffect(() => {
    setCurrentUrl(pathname)
  }, [pathname])

  const sortRewardListByDateCreated = (
    data: ParentReferralReward[]
  ): ParentReferralReward[] => {
    return data.slice().sort((a, b) => {
      const dateA = new Date(a.date_created).getTime();
      const dateB = new Date(b.date_created).getTime();
      return dateB - dateA;
    });
  };

  React.useEffect(() => {
    if (token !== undefined) {
      let callback = (data: { result: any | null; error: string | null }) => {
        if (data.error !== null) {
          let messageProp: PopupContentInterface = {
            title: ERROR_POPUP_TITLE,
            message: data.error,
            image: null,
            withCloseButton: true,
            isErrorPopup: true,
          };
          appContext?.setPopupContent(messageProp);
          appContext?.setIsPopupMessageOpen(true);
        } else {
          const result = data.result;
          if (result.code !== undefined) {
            if (result!.code !== 200) {
              let messageProp: PopupContentInterface = {
                title: ERROR_POPUP_TITLE,
                message: ERROR_MESSAGE_DEFAULT,
                image: null,
                withCloseButton: true,
                isErrorPopup: true,
              };
              appContext?.setPopupContent(messageProp);
              appContext?.setIsPopupMessageOpen(true);
            } else if (result!.message === 'error' && result.payload === null) {
              let messageProp: PopupContentInterface = {
                title: ERROR_POPUP_TITLE,
                message: result.description,
                image: null,
                withCloseButton: true,
                isErrorPopup: true,
              };
              appContext?.setPopupContent(messageProp);
              appContext?.setIsPopupMessageOpen(true);
              // show message box error from result.description
            } else {
              const sortedApiResult = sortRewardListByDateCreated(
                result.payload
              );
              setRewardList(sortedApiResult);
              setValidated(true);
            }
          } else {
            setValidated(false);
          }
        }
      };
      const headers = {
        Authorization: token,
        'Content-Type': 'application/json',
      };
      APIRequest(
        process.env.REACT_APP_REFERRAL_API_URL +
          'parent-referral-reward/rewards',
        'GET',
        headers,
        null,
        callback
      );
    } else {
      navigate('/404');
    }
  }, [token]);

  // const load = (step: number) => {
  //   let pageName = 'Main Page';
  //   let currentURL = window.location.href;

  //   // build a state for this name
  //   var state = { step: step };
  //   window.history.pushState(state, pageName, currentURL);
  // };

  useEffect(() => {
    // load(step);

    // window.addEventListener('popstate', () => onPopState());
    // onPopState();

    if (containerEl.current !== null) containerEl.current.addEventListener("scroll", setScroll);
    setScroll();
    return () => {
      // window.removeEventListener('resize', () => onPopState());
      if (containerEl.current !== null) containerEl.current.removeEventListener("scroll", setScroll);
    };
  }, []);

  function setScroll() {
    let url = window.location.href;
    if (containerEl.current !== null && url.indexOf('reward-list') !== -1) setScrollTopVal(containerEl.current.scrollTop);
  }

  // const onPopState = () => {
  //   if (
  //     window.history.state &&
  //     window.history.state.step === REFERRAL_MOBILE_STEP_MAIN_PAGE
  //   ) {
  //     setStep(window.history.state.step);
  //   }
  // };
  
  useEffect(() => {
    if (containerEl.current !== null) {
      if (step === REFERRAL_MOBILE_STEP_MAIN_PAGE) containerEl.current.scrollTop = scrollTopVal;
      else containerEl.current.scrollTop = 0;
    }
  }, [step]);

  useEffect(() => {
    if (currentUrl.indexOf('reward-list') !== -1) {
      if (step !== REFERRAL_MOBILE_STEP_REWARD_LIST) setStep(REFERRAL_MOBILE_STEP_REWARD_LIST);
    } else {
      if (step !== REFERRAL_MOBILE_STEP_MAIN_PAGE) setStep(REFERRAL_MOBILE_STEP_MAIN_PAGE);
    }
  }, [currentUrl]);

  return (
    <>
      <div ref={containerEl} className="max-h-screen overflow-auto disable-scrollbar-style">
        {validated === null && <Preloading className='z-10' />}
        {validated === true && step === REFERRAL_MOBILE_STEP_MAIN_PAGE && (
          <MainPage rewardList={rewardList} setStep={setStep} token={token!} />
        )}
        {validated === true && step === REFERRAL_MOBILE_STEP_REWARD_LIST && (
          <YourInvitesAndRewards rewardList={rewardList} />
        )}
        {validated === false && <Error404 />}
      </div>
    </>
  );
};

export default ReferralMobile;
