import React from 'react';
import RewardIcon, { RewardIconProps } from './RewardIcon';
import Badge, { BadgeProps } from './Badge';
import IconChevronRight from '../../images/icon-chevron-right.svg';
import { ParentReferralReward } from '../../pages/ReferralMobile';
import { DEVICE_ANDROID, DEVICE_IOS, PRIZE_TYPE, REWARD_PHASE, REWARD_TYPE } from '../../actions/Constants';

type RewardItemProps = {
  referee?: ParentReferralReward['referee_name'];
  showSelect?: boolean; // will show "select reward" link after the footnote
  parentReferralRewardID: number;
} & RewardInfo;

type RewardIconKey = keyof RewardIconProps;
type RewardItemText = {
  title: string;
  footnote: string;
  rewardType: RewardIconKey;
};

type RewardInfo = {
  prizeId: ParentReferralReward['prize_id'];
  rewardDescription: ParentReferralReward['reward_description'];
  version: ParentReferralReward['version_reward'];
  status: {
    delivered: ParentReferralReward['status_delivered'];
    redeemed: ParentReferralReward['status_redeemed'];
    progress: ParentReferralReward['referral_progress_id'];
  };
};

const getRewardType = (
  param: ParentReferralReward['reward_description']
): RewardIconKey => {
  const lowerParam = param.toLowerCase();

  if (lowerParam === '500 bubbles') return REWARD_TYPE.INITIAL_BUBBLE;
  if (lowerParam.includes('cash')) return REWARD_TYPE.CASHBACK;

  return REWARD_TYPE.BUBBLES;
};

// change the `$` to `S$` and `Cashback` to `Cash`
function modifyText(input: string): string {
  let result = input.replace(/(^|\s)\$(?!\$)/g, '$1S$');
  result = result.replace(/Cashback/g, 'Cash');
  return result;
}

const getRewardInfo = (params: RewardInfo): RewardItemText => {
  const isInitialReward = params.rewardDescription === '500 Bubbles';
  const modifiedRewardDescription = modifyText(params.rewardDescription)

  // `undecided` reward type will skip the `getRewardType` function
  const rewardType =
    params.prizeId === PRIZE_TYPE.UNDECIDED
      ? REWARD_TYPE.UNDECIDED
      : getRewardType(modifiedRewardDescription);

  const footnote: string = isInitialReward
    ? 'Initiate a referral for the first time'
    : rewardType === REWARD_TYPE.UNDECIDED ? 'Get reward for every successful Referral':'Make a successful referral';

  const title: string =
    params.prizeId === PRIZE_TYPE.UNDECIDED
      ? '5000 Bubbles or S$80 Cash'
      : String(modifiedRewardDescription);

  return {
    title,
    footnote,
    rewardType,
  };
};

const SelectRewardComponent = ({
  rewardType,
  parentReferralRewardID,
  prizeID
}: {
  rewardType: RewardIconKey;
  parentReferralRewardID: number;
  prizeID: number;
}) => {
  function openSelectRewardPopup() {
    window.hook_openSelectRewardPopup(parentReferralRewardID, prizeID);
  }

  return (
    <div
      className={`mt-4 flex h-6 w-fit items-center gap-1 rounded-lg text-center text-[12px] font-normal italic leading-[18px] ${
        rewardType === REWARD_TYPE.UNDECIDED ? 'px-2 py-1 bg-[#fde9d1]' : ''
      }`}
      onClick={openSelectRewardPopup}
    >
      <span className='text-secondary font-dm-sans'>
        {rewardType === REWARD_TYPE.UNDECIDED
          ? 'Select reward'
          : 'Change reward'}
      </span>
      <img
        src={IconChevronRight}
        alt='back'
        className='w-[9px] filter-orange'
      />
    </div>
  );
};

const RewardItem = (props: RewardItemProps) => {
  const {
    rewardDescription,
    version,
    status,
    prizeId,
    parentReferralRewardID,
  } = props;
  const rewardInfo = getRewardInfo({
    rewardDescription,
    version,
    status,
    prizeId,
  });

  // already delivered reward will have `green` variant, else `yellow`. not delivered nor redeemed will be `red`
  const rewardProgressVariant: BadgeProps['variant'] = props.status.delivered
    ? 'green'
    : props.status.progress === null || props.status.progress === 0
    ? 'red'
    : 'yellow';

  // logic whether to show Select Reward badge below footnote
  const ongoingReferral =
    rewardProgressVariant !== 'green' &&
    rewardInfo.rewardType !== REWARD_TYPE.INITIAL_BUBBLE &&
    props.showSelect;

  const paddingRewardItem =
    (rewardInfo.rewardType === REWARD_TYPE.CASHBACK ||
      rewardInfo.rewardType === REWARD_TYPE.BUBBLES) &&
    ongoingReferral
      ? 'pt-4 pb-[11px]'
      : 'py-4';
  return (
    <>
      <div className={`reward-item ${paddingRewardItem} mx-[15px]`}>
        <RewardIcon variant={rewardInfo.rewardType} />

        <div className='flex flex-col ml-2 mr-3'>
          <span className='text-base font-bold text-left text-primary leading-[21px] mb-1 font-dm-sans'>
            {rewardInfo.title}
          </span>
          <span className='line-clamp-2 break-words text-left text-sm leading-[18px] font-dm-sans' style={{wordBreak: "break-word"}}>
            {rewardProgressVariant !== 'red'
              ? `For referring: ${props.referee ?? ''}`
              : rewardInfo.footnote}
          </span>
          {ongoingReferral && props.version === REWARD_PHASE.PHASE_TWO && (
            <SelectRewardComponent
              rewardType={rewardInfo.rewardType}
              parentReferralRewardID={parentReferralRewardID}
              prizeID={prizeId}
            />
          )}
        </div>
        <div className='flex items-start ml-auto'>
          <Badge variant={rewardProgressVariant} />
        </div>
      </div>
    </>
  );
};

RewardItem.defaultProps = {
  showSelect: true,
};

export default RewardItem;
