import * as React from 'react';
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../components/AppContext";
import ActiveReferrals from '../components/ActiveReferrals';
import PrizeList from '../components/PrizeList';
import IconCopy from '../images/icon-copy.svg';
import IconEllipsis from '../images/icon-ellipsis.svg';
import IconDownload from '../images/icon-download.svg';
import IconChevronLeft from '../images/icon-chevron-left.svg';
import IconChevronRight from '../images/icon-chevron-right.svg';
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon
} from 'react-share'
import { APIRequest } from '../actions/APIRequest';
import {
    DEFAULT_POSTER_INFO,
    DEFAULT_SHARE_TEXT,
    ERROR_MESSAGE_DEFAULT,
    ERROR_POPUP_TITLE,
    JWT_TOKEN_VAR_NAME,
    TOAST_DEFAULT_BUTTON_TEXT,
    TOAST_TYPE_DEFAULT,
    TOAST_TYPE_SUCCESS
} from '../actions/Constants';
import Poster1 from "../components/Poster1";
import Poster2 from "../components/Poster2";
import {useStateCallback} from "../actions/UseStateCallback";
import DefaultPoster1 from "../images/poster/default-poster1.png";
import DefaultPoster2 from "../images/poster/default-poster2.png";
import DefaultChildPhoto1 from "../images/default-child-photo/default-1.jpg";
import DefaultChildPhoto2 from "../images/default-child-photo/default-2.jpg";
import ShareMorePopup from '../components/ShareMorePopup';
import PopupContentInterface from '../interfaces/PopupContentInterface';
import PosterDataInterface from "../interfaces/PosterDataInterface";
import {PosterInfoHandler} from "../actions/PosterInfoHandler";
import {getFontCSS, PosterImageHandler} from "../actions/PosterImageHandler";
import jwt_decode from "jwt-decode";
import {useState} from "react";
import ClosedPage from '../components/ClosedPage';

export interface PropsType {
    fontCSS: string;
    isWebClosed: boolean;
}

export default function Root(props: PropsType) {
    const appContext = React.useContext(AppContext);
    const navigate = useNavigate();
    const [posterTypeActive, setPosterTypeActive] = React.useState<number>(1);
    const [tabIndexActive, setTabIndexActive] = React.useState<number>(0);
    const [isShareMorePopupOpen, setIsShareMorePopupOpen] = React.useState<boolean>(false);
    const [shareTextValue, setShareTextValue] = React.useState<string>('');
    const numOfPoster = 2;

    const poster1Ref = React.useRef<HTMLDivElement>(null);
    const poster2Ref = React.useRef<HTMLDivElement>(null);

    const [poster1Img, setPoster1Img] = React.useState<string>('');
    const [poster2Img, setPoster2Img] = React.useState<string>('');
    const [childPhotoPoster, setChildPhotoPoster] = useStateCallback<string>(DefaultChildPhoto1)
    const [posterData, setPosterData] = React.useState<PosterDataInterface>({
        child_name: "John Lee",
        subject_name: "Math",
        num_bubbles: "1000",
        num_worksheets: "100",
        subject_pct: 80,
        expiry_date: 0
    });

    const title = 'Geniebook Referral Program';
    const [referralLink, setReferralLink] = useState<string>(encodeURI(process.env.REACT_APP_REFERRAL_LINK+"/"))

    React.useEffect(() => {
        const token = localStorage.getItem(JWT_TOKEN_VAR_NAME);
        if (token !== null) PosterInfoHandler(token, (data: { poster_info: PosterDataInterface }) =>{
            setPosterData(data.poster_info);
        });
        else {
            setPosterData(DEFAULT_POSTER_INFO);
        }

        if (token === null) setChildPhotoPoster(DefaultChildPhoto1, () => {handlePosterImage()});
        else getPhotoIndex(token);

        if (token !== null) {
            const userData:any = jwt_decode(token)
            const name: string = userData.name.length > 15 ? userData.name.substring(0,15) : userData.name
            const RefLink = process.env.REACT_APP_REFERRAL_LINK+
                "?i="+userData.user_id+
                "&n="+name+
                "&m="+userData.mobile+
                "#lead_source=Parent%20Referral%20Product&campaign=Parent%20App%20Referral";
            setReferralLink(encodeURI(RefLink));
        } else {
            setReferralLink(process.env.REACT_APP_REFERRAL_LINK+"")
        }

        if (token !== null) getShareText(token);
        else setShareTextValue(DEFAULT_SHARE_TEXT.replaceAll('{referralLink}', referralLink));

        handlePosterImage()
    }, [appContext?.userData])

    React.useEffect(()=>{handlePosterImage()}, [poster1Img, poster2Img, posterData, props.fontCSS])

    const handlePrevPoster = () => {
        let posterType = posterTypeActive;
        posterType--;
        if (posterType < 1) posterType = numOfPoster;
        setPosterTypeActive(posterType);
    }
  
    const handleNextPoster = () => {
        let posterType = posterTypeActive;
        posterType++;
        if (posterType > numOfPoster) posterType = 1;
        setPosterTypeActive(posterType);
    }

    function getPhotoIndex(token:string) {
        const headers = {
            'Authorization': token
        }
        APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'referral-poster/get-photo-index', 'GET', headers, null, (data:{result:any|null, error:string|null}) => {
            if (data.error !== null) {
              let messageProp:PopupContentInterface = {
                  title: ERROR_POPUP_TITLE,
                  message: data.error,
                  image: null,
                  withCloseButton: true,
                  isErrorPopup: true,
              }
              appContext?.setPopupContent(messageProp);
              appContext?.setIsPopupMessageOpen(true);
            } else {
                const result = data.result;
                if (result!.code !== 200) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: ERROR_MESSAGE_DEFAULT,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                } else if (result!.message === 'error' && result.payload === null) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: result.description,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                    // show message box error from result.description
                } else {
                    let payload = result.payload;
                    switch (payload){
                        case 0 : {
                            setChildPhotoPoster(DefaultChildPhoto1, () => {handlePosterImage()});
                            break;
                        } case 1 : {
                            setChildPhotoPoster(DefaultChildPhoto2, () => {handlePosterImage()});
                            break;
                        } case 2 : {
                            const headers = {
                                'Authorization': token
                            }
                            APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'parent-child-photo', 'GET', headers, null, (data:{result:any|null, error:string|null}) => {
                                if (data.error !== null) {
                                    // show message box error
                                } else {
                                    const result = data.result;
                                    if (result!.code !== 200) {
                                        // show message box error from result!.error_msg
                                    } else {
                                        let payload = result.payload;
                                        if (payload !== null) {
                                            setChildPhotoPoster(payload.photo_file_url, () => {handlePosterImage()});
                                        }
                                    }
                                }
                            });
                            break;
                        } default : {
                            setChildPhotoPoster(DefaultChildPhoto1, () => {handlePosterImage()});
                            break;
                        }
                    }
                }
            }
        });
    }

    const handlePosterImage = () => {
            PosterImageHandler(poster1Ref, props.fontCSS,(data : {poster_image: string}) => {
                setPoster1Img(data.poster_image)
            })

            PosterImageHandler(poster2Ref, props.fontCSS,(data: { poster_image: string }) => {
                setPoster2Img(data.poster_image)
            })
    }

    const handleCustomizeLink = (e: any) => {
        if (appContext!.userData === null) {
            e.preventDefault();
            appContext!.setIsPopupLoginOpen(true);
        }
    }

    function createDefaultShareText(token: string) {
        let apiData = {
            share_text: DEFAULT_SHARE_TEXT.replaceAll('{referralLink}', referralLink),
        };
        const headers = {
            'Authorization': token
        }
        APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'referral-poster/create-update-share-text', 'POST', headers, JSON.stringify(apiData), (data:{result:any|null, error:string|null})=>{
            if (data.error !== null) {
                let messageProp:PopupContentInterface = {
                    title: ERROR_POPUP_TITLE,
                    message: data.error,
                    image: null,
                    withCloseButton: true,
                    isErrorPopup: true,
                }
                appContext?.setPopupContent(messageProp);
                appContext?.setIsPopupMessageOpen(true);
            } else {
                const result = data.result;
                if (result!.code !== 200) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: ERROR_MESSAGE_DEFAULT,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                } else if (result!.message === 'error' && result.payload === null) {
                    let messageProp:PopupContentInterface = {
                        title: ERROR_POPUP_TITLE,
                        message: result.description,
                        image: null,
                        withCloseButton: true,
                        isErrorPopup: true,
                    }
                    appContext?.setPopupContent(messageProp);
                    appContext?.setIsPopupMessageOpen(true);
                    // show message box error from result.description
                } else {
                    setShareTextValue(DEFAULT_SHARE_TEXT.replaceAll('{referralLink}', referralLink));
                }
            }
        });
    }

    function getShareText(token:string) {
      let callback = (data:{result:any|null, error:string|null}) => {
          if (data.error !== null) {
              let messageProp:PopupContentInterface = {
                  title: ERROR_POPUP_TITLE,
                  message: data.error,
                  image: null,
                  withCloseButton: true,
                  isErrorPopup: true,
              }
              appContext?.setPopupContent(messageProp);
              appContext?.setIsPopupMessageOpen(true);
          } else {
              const result = data.result;
              if (result!.code !== 200) {
                  let messageProp:PopupContentInterface = {
                      title: ERROR_POPUP_TITLE,
                      message: ERROR_MESSAGE_DEFAULT,
                      image: null,
                      withCloseButton: true,
                      isErrorPopup: true,
                  }
                  appContext?.setPopupContent(messageProp);
                  appContext?.setIsPopupMessageOpen(true);
              } else if (result!.message === 'error' && result.payload === null) {
                  let messageProp:PopupContentInterface = {
                      title: ERROR_POPUP_TITLE,
                      message: result.description,
                      image: null,
                      withCloseButton: true,
                      isErrorPopup: true,
                  }
                  appContext?.setPopupContent(messageProp);
                  appContext?.setIsPopupMessageOpen(true);
                  // show message box error from result.description
              } else {
                  let payload = result.payload;
                  if (payload !== '') {
                      setShareTextValue(payload);
                  } else {
                      createDefaultShareText(token)
                  }
              }
          }
      }
      const headers = {
          'Authorization': token
      }
      APIRequest(process.env.REACT_APP_REFERRAL_API_URL + 'referral-poster/get-share-text', 'GET', headers, null, callback);
    }

    function copyToClipboard(text:string, textDesc:string) {
        if (appContext!.userData === null) appContext!.setIsPopupLoginOpen(true);
        else {
            navigator.clipboard.writeText(text)
            openToast(TOAST_TYPE_SUCCESS, textDesc + ' copied to clipboard.');
        }
    }

    const handleDownloadPosters = () => {
        const link = document.createElement('a')
        link.download = 'poster-image.png'
        if (posterTypeActive === 1){
            link.href = poster1Img
        }
        if (posterTypeActive === 2){
            link.href = poster2Img
        }
        link.click();
        openToast(TOAST_TYPE_SUCCESS, 'Poster successfully saved to your device.');
    };

    function openToast(toastType = TOAST_TYPE_DEFAULT, toastMessage1 = '', toastMessage2 = '', toastButtonText = TOAST_DEFAULT_BUTTON_TEXT, toastCallback = null) {
        appContext?.setIsToastOpen(true);
        appContext?.setToastCallback(toastCallback);
        appContext?.setToastButtonText(toastButtonText);
        appContext?.setToastType(toastType);
        appContext?.setToastMessage1(toastMessage1);
        appContext?.setToastMessage2(toastMessage2);
    }

    return (
      <>
      {props.isWebClosed ? 
        <div className='flex items-center justify-center h-screen max-lg:px-[64px] max-lg:pt-[143px] max-lg:pb-[80px] max-lg:block max-lg:h-auto max-md:px-4 max-md:pt-[103px] max-md:pb-10'>
            <ClosedPage />
        </div>
        :
        <>
            <div className="row homepage-content">
            <div className="w-1/2 col">
                <div className="home-poster">
                <p className="share-poster-title">Share poster with your friends and gain rewards!</p>
                <div className="relative row">
                    <div className="poster-nav poster-nav-prev" onClick={handlePrevPoster}><img src={IconChevronLeft} /></div>
                    <div className="poster-slider">
                    <div className={"w-1/2 col " + (posterTypeActive === 1 ? "active ": "") + "poster-container"}>
                        <div className={(posterTypeActive === 1 ? "active ": "") + "poster-1 poster"} onClick={() => {appContext!.userData === null ? appContext!.setIsPopupLoginOpen(true) : setPosterTypeActive(1)}} data-index="0">
                            <div className="hidden text-left">
                            <Poster1 ref={poster1Ref} getPhotoSrc={childPhotoPoster} qrShareLink={referralLink}/>
                            </div>
                            <img className={(posterTypeActive === 1 ? "active ": "") + "poster-img"} src={poster1Img === '' ? DefaultPoster1 : poster1Img} alt="Image Poster 1"/>
                        </div>
                    </div>
                    <div className={"w-1/2 col " + (posterTypeActive === 2 ? "active ": "") + "poster-container"}>
                        <div className={(posterTypeActive === 2 ? "active ": "") + "poster-2 poster"} onClick={() => {appContext!.userData === null ? appContext!.setIsPopupLoginOpen(true) : setPosterTypeActive(2)}} data-index="1">
                            <div className="hidden text-left">
                                <Poster2 ref={poster2Ref} getPhotoSrc={childPhotoPoster} qrShareLink={referralLink} posterData={posterData}/>
                            </div>
                            <img className={(posterTypeActive === 2 ? "active ": "") + "poster-img"} src={poster2Img === '' ? DefaultPoster2 : poster2Img} alt="Image Poster 2"/>
                        </div>
                    </div>
                    </div>
                    <div className="poster-nav poster-nav-next" onClick={handleNextPoster}><img src={IconChevronRight} /></div>
                </div>
                <div id="home-poster-nav" className="nav-points">
                    {
                        Array.from(Array(numOfPoster), (e, i) => {
                            return <div className={"nav-point" + (posterTypeActive === (i + 1) ? " active" : "")} key={i} onClick={() => {setPosterTypeActive(i + 1)}}></div>
                        })
                    }
                </div>
                <div className="share-container">
                    <div className="copy-link" onClick={() => {copyToClipboard(referralLink, "Referral Link")}}>
                    <div className="copy-link-container"><p>{referralLink}</p></div>
                    <button id="tracker-copy-link"><img src={IconCopy} /><label>Copy</label></button>
                    </div>
                    <div className="share-container-buttons">
                        <p className="share-label">Share:</p>
                        <button id="tracker-download" className="download-button" onClick={() => {appContext!.userData === null ? appContext!.setIsPopupLoginOpen(true) : handleDownloadPosters()}}><img src={IconDownload} /></button>

                        <WhatsappShareButton id="tracker-share-whatsapp" url={shareTextValue.includes("http") ? " " : referralLink} title={shareTextValue} resetButtonStyle={false} openShareDialogOnClick={appContext!.userData !== null} onClick={() => {appContext!.userData === null ? appContext!.setIsPopupLoginOpen(true) : appContext!.setIsPopupLoginOpen(false)}}>
                        <WhatsappIcon size={34} round bgStyle={{fill: '#F7941E'}} />
                        </WhatsappShareButton>

                        <FacebookShareButton id="tracker-share-facebook" url={referralLink} title={title} quote={shareTextValue} hashtag={'#Geniebook'} resetButtonStyle={false} openShareDialogOnClick={appContext!.userData !== null} onClick={() => {appContext!.userData === null ? appContext!.setIsPopupLoginOpen(true) : appContext!.setIsPopupLoginOpen(false)}}>
                        <FacebookIcon size={39.75} round bgStyle={{fill: '#F7941E'}} />
                        </FacebookShareButton>

                        <button className="share-more" onClick={() => {appContext!.userData === null ? appContext!.setIsPopupLoginOpen(true) : setIsShareMorePopupOpen(true)}}><img src={IconEllipsis} /></button>
                    </div>
                </div>
                <div className="separator"></div>
                <p className="label-generate-poster">Want to increase you chances of doing a successful referral?</p>
                <Link to="customise-poster" className="link-generate-poster" onClick={(e) => handleCustomizeLink(e)}>Click here to customise your poster</Link>
                </div>
            </div>
            <div className="w-1/2 col">
                <div className="tab-container">
                <div className="tab-button-container">
                    <div className={(tabIndexActive === 0 ? "active " : "") + "tab-item"} onClick={() => {appContext!.userData === null ? appContext!.setIsPopupLoginOpen(true) : setTabIndexActive(0)}}>Rewards</div>
                    <div className={(tabIndexActive === 1 ? "active " : "") + "tab-item"} onClick={() => {appContext!.userData === null ? appContext!.setIsPopupLoginOpen(true) : setTabIndexActive(1)}}>Active Referrals</div>
                </div>
                <div className="tab-content-container">
                    <div className={(tabIndexActive === 1 ? "hidden " : "") + "card md:max-h-[691px] overflow-hidden px-[18px] py-[21.75px]"}>
                    <PrizeList />
                    </div>
                    <div className={(tabIndexActive === 0 ? "hidden " : "") + "card active-referral-container"}>
                    <ActiveReferrals />
                    </div>
                </div>
                </div>
            </div>
            </div>

            <ShareMorePopup isPopupOpen={isShareMorePopupOpen} setIsPopupOpen={setIsShareMorePopupOpen} popupTitle="Other Sharing Options" referralLink={referralLink} linkTitle={title} shareTextValue={shareTextValue} downloadPoster={handleDownloadPosters} copyLink={() => {copyToClipboard(referralLink, "Referral Link")}}/>
        </>
        }
      </>
    );
  }