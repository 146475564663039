import React, { useState } from "react";
import RewardItem from "../../components/referral-mobile/RewardItem";
import FaqSection from "../../components/referral-mobile/FaqSection";
import IconChevronRight from "../../images/icon-chevron-right.svg";
import { ParentReferralReward, getRefereeName } from "../ReferralMobile";
import { INIT_REWARD_ID, REFERRAL_MOBILE_STEP_REWARD_LIST } from "../../actions/Constants";
import { Link } from "react-router-dom";
import ReferralPoster from "../../images/poster/phase-2-poster.svg";

const MainPage = ({
    rewardList,
    token,
    setStep
  }: {
    rewardList: ParentReferralReward[];
    token: string;
    setStep: React.Dispatch<React.SetStateAction<number>>;
  }) => {
    const currentURL = window.location.href;
    const [baseURL, setBaseURL] = useState<string>(currentURL.split('referralMobile')[0]);

    const redirectToRewardList = () => {
        let currentURL = window.location.href;
        let baseURL = currentURL.split('referralMobile')[0];
        var state = {step: REFERRAL_MOBILE_STEP_REWARD_LIST};
        window.history.pushState(state, "Reward List", baseURL + 'referralMobile/reward-list/' + token);
        setStep(REFERRAL_MOBILE_STEP_REWARD_LIST);
    }

  return (
    <>
      <div className="flex flex-col overflow-y-auto bg-white disable-scrollbar-style">
          <section
            id="poster"
            className="bg-[#26215E] flex justify-center items-center min-h-[400px]"
          >
            <img src={ReferralPoster} className="max-h-[425px]" />
          </section>

          {/* scrollable div */}
          <section>
            <div className="flex flex-grow flex-col bg-[#edf1f8]">
              <Link to={baseURL + 'referralMobile/reward-list/' + token} onClick={() => {setStep(REFERRAL_MOBILE_STEP_REWARD_LIST)}}>
                <span>
                      <div className="flex items-center justify-between pt-6 pb-2 select-none mx-[15px]">
                          <div className="font-bold text-left text-primary leading-[21px] font-dm-sans">
                            Invites and Rewards
                          </div>
                          <img
                              src={IconChevronRight}
                              alt="back"
                              className="w-[12px] filter-orange"
                          />
                      </div>

                      {/* Reward List */}
                      {rewardList.slice(0, 2).map((item) => (
                        <RewardItem
                          key={item.parent_referral_reward_id}
                          prizeId={item.prize_id}
                          rewardDescription={item.reward_description}
                          showSelect={false}
                          version={item.version_reward}
                          status={{
                            delivered: item.status_delivered,
                            redeemed: item.status_redeemed,
                            progress: item.referral_progress_id,
                          }}
                          referee={getRefereeName(item, rewardList)}
                          parentReferralRewardID={item.parent_referral_reward_id}
                        />
                      ))}
                </span>
              </Link>

              <FaqSection />
            </div>
          </section>
          <div id="bottom_action"></div>
        </div>
    </>
  );
};

export default MainPage;
