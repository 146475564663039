import { Link, useNavigate } from "react-router-dom";
import AppContext from "./AppContext";
import * as React from 'react';
import logo_desktop from '../images/logo/logo_desktop.svg';
import logo_mobile from '../images/logo/logo_mobile.svg';
import {JWT_TOKEN_VAR_NAME, POSTER_DATA_VAR_NAME} from "../actions/Constants";
import Popup from "reactjs-popup";

const Header = ({isWebClosed}:{isWebClosed: boolean}) => {
    const appContext = React.useContext(AppContext);
    const navigate = useNavigate();
    const [isProfilePopupOpen, setIsProfilePopupOpen] = React.useState<boolean>(false);

    const handleLogout = () => {
        appContext!.setUserData(null);
        localStorage.removeItem(JWT_TOKEN_VAR_NAME);
        localStorage.removeItem(POSTER_DATA_VAR_NAME);
        setIsProfilePopupOpen(false);
        navigate("/");
    }

    window.addEventListener('storage', (event) => {
        if (event.storageArea != localStorage) return;
        if (event.key === JWT_TOKEN_VAR_NAME) {
          if (appContext!.userData !== null && localStorage.getItem(JWT_TOKEN_VAR_NAME) === null) {
            handleLogout();
          }
        }
    });
    
    return (
        <>
            <header className="fixed z-50 w-full bg-white">
                <div className="header-mobile">
                    {!isWebClosed && <a href="/faq" target="_blank" className="faq-link">
                        <div className="faq">
                            <label>?</label>
                        </div>
                    </a>}

                    <Link to="/">
                        <img src={logo_mobile} className="logo" />
                    </Link>

                    { 
                        appContext!.userData !== null ?
                        <>
                            {!isWebClosed && <div className="profile-picture" onClick={() => {setIsProfilePopupOpen(true)}}>
                                {
                                    appContext!.userData.pp_url !== ''?
                                    <img src={appContext!.userData.pp_url} />:''
                                }
                            </div>}
                        </>: 
                        <>
                            {!isWebClosed && <button className="login-button" onClick={() => {appContext!.setIsPopupLoginOpen(true)}}>Login</button>}
                        </>                       
                    }
                </div>
                <div className="header-desktop">
                    <Link to="/">
                        <img src={logo_desktop} className="logo" />
                        {/* <label className="text-logo">Referral Program</label> */}
                    </Link>

                    {!isWebClosed && <div className="">
                        <a href="/faq" target="_blank">
                            <div className="faq">
                                <label>?</label>
                            </div>
                        </a>
                        {
                            appContext!.userData !== null ?
                            <>
                                <div className="profile-picture">
                                    {
                                        appContext!.userData.pp_url !== ''?
                                        <img src={appContext!.userData.pp_url} />:''
                                    }
                                </div>
                                <div className="profile-container">
                                    <p className="profile-name" title={appContext!.userData.name}>{appContext!.userData.name}</p>
                                    <button className="logout" onClick={handleLogout}>Logout</button>
                                </div>
                            </>: 
                            <button className="login-button" onClick={() => {appContext!.setIsPopupLoginOpen(true)}}>Login</button>
                        }
                    </div>}
                </div>
            </header>
            <Popup open={isProfilePopupOpen} onClose={() => {setIsProfilePopupOpen(false)}} className="popup-overlay profile-popup" lockScroll>
                <div className="relative mx-auto md:h-auto">
                    <div className="relative bg-white rounded-[10px] shadow px-[27px] py-[24px]">
                        <button className="w-full button button-logout mb-[22px]" onClick={handleLogout}>Logout</button>
                        <button className="w-full bg-white button text-secondary font-campton text-[15px] font-extrabold" onClick={() => {setIsProfilePopupOpen(false)}}>Cancel</button>
                    </div>
                </div>
            </Popup>
        </>
    );
}
 
export default Header;