import React from "react";
import UndecidedReward from "../../images/rewardType-undecided_light-blue.svg";
import CashbackReward from "../../images/rewardType-cashback.svg";
import BubblesReward from "../../images/rewardType-bubbles.svg";
import { REWARD_TYPE } from "../../actions/Constants";

export type RewardIconProps = {
  [key: number]: {
    style: string;
    src: string;
    alt: string;
  };
};

const variantProps: Record<number, RewardIconProps[number]> = {
  [REWARD_TYPE.INITIAL_BUBBLE]: {
    //! might need to remove the 'style' key if the image source is fixed
    style: "bg-[#c7eaf9]",
    src: BubblesReward,
    alt: "Initial referral",
  },
  [REWARD_TYPE.UNDECIDED]: {
    style: "bg-[#262262]",
    src: UndecidedReward,
    alt: "Undecided",
  },
  [REWARD_TYPE.CASHBACK]: {
    style: "bg-[#f7941e]",
    src: CashbackReward,
    alt: "Cashback",
  },
  [REWARD_TYPE.BUBBLES]: {
    style: "bg-[#c7eaf9]",
    src: BubblesReward,
    alt: "Bubbles",
  },
};

const RewardIcon = ({ variant }: { variant: keyof RewardIconProps }) => {
  const variantData = variantProps[variant];
  const { style, src, alt } = variantData;
  return <img src={src} alt={alt} />;
};

export default RewardIcon;
