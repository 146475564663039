import * as React from 'react';
import { TOAST_DEFAULT_BUTTON_TEXT, TOAST_TYPE_DEFAULT, TOAST_TYPE_SUCCESS } from '../actions/Constants';
import IconCheck from '../images/icon-circle-check.svg';
import IconWarning from '../images/icon-triangle-exclamation.svg';
import IconDanger from '../images/icon-trash.svg';
import AppContext from './AppContext';

interface ToastProp {
    toastType: number;
    isToastOpen:boolean;
    toastMessage1:string;
    toastMessage2:string;
    toastButtonText:string;
    toastCallback:(() => void) | null;
}

const Toast = (prop:ToastProp) => {
    const appContext = React.useContext(AppContext);
    const toastTypeClassMap = ['', ' success', ' warning', ' error'];
    const toastIconMap = ['', IconCheck, IconWarning, IconDanger];

    const handleToastButtonClick = () => {
        if (prop.toastCallback !== null) {
            prop.toastCallback();
            dismissToast();
        } else {
            dismissToast();
        }
    }

    function dismissToast() {
        appContext?.setIsToastOpen(false);
        appContext?.setToastCallback(null);
        appContext?.setToastButtonText(TOAST_DEFAULT_BUTTON_TEXT);
        appContext?.setToastType(TOAST_TYPE_DEFAULT);
        appContext?.setToastMessage1('');
        appContext?.setToastMessage2('');
    }

    return (
        <div className={"toast" + (toastTypeClassMap[prop.toastType]) + (prop.isToastOpen === false ? ' hidden' : '')}>
            { prop.toastType !== TOAST_TYPE_DEFAULT && <div className="toast-icon"><img src={toastIconMap[prop.toastType]} /></div> }
            <div className="toast-message">
                <p className="toast-message1">{prop.toastMessage1}</p>
                <p className="toast-message2">{prop.toastMessage2}</p>
            </div>
            <p className="toast-button" onClick={handleToastButtonClick}>{prop.toastButtonText}</p>
        </div>
    );
}
 
export default Toast;